import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsPoPComponent implements OnInit,  OnDestroy {
  @Input() orderDetails: any;
  zoom_m1 = 18;
  origin;
  destination;

  public lat: Number = 24.799448
  public lng: Number = 120.979021

  markerClient: marker = {
    lat: 0,
    lng: 0,
    label: ''
  }
  markerDriver: marker = {
    lat: 0,
    lng: 0,
    label: ''
  };
  labelClient = '';
  labelDriver = '';

  sp: Subscription;
  constructor() {}

  ngOnInit(): void {
    this.getClientLocation()
  }
    
  ngOnDestroy(): void {
    if(String(this.orderDetails?.StatusId) == '3'){
      this.sp.unsubscribe();
    }
  }

  clickedMarker(label: string) {
    this.labelClient = this.markerClient.label
  }

  clickedMarkerDriver(label: string) {
    this.labelDriver = this.markerDriver.label
  }

  getDirection() {
    this.destination = {
      lat: this.orderDetails?.lat,
      lng: this.orderDetails?.lng,
    };
  }

  getClientLocation(){
    this.markerClient.lat = this.orderDetails?.lat,
    this.markerClient.lng = this.orderDetails?.lng,
    this.markerClient.label = 'العميل'
  }
}


interface marker {
  lat: number;
  lng: number;
  label?: string
}

