import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../base/base.component';
import { ConstantServiceWrapper } from '../../../service/ConstantServiceWrapper.service';
import { environment } from '../../../../environments/environment';
import { MouseEvent } from "@agm/core";
import { appConstant } from 'src/app/service/appConstant';


@Component({
  selector: 'app-branch-add-edit',
  templateUrl: './branch-add-edit.component.html',
  styleUrls: ['./branch-add-edit.component.scss']
})
export class branchAddEditComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();
  formData = new FormData();
  markers = [];
  countries = [];
  imgUploaded = false;

  lng =  appConstant.LONG
  lat = appConstant.LAT
  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }


  ngOnInit(): void {
    this.getCountries()
    this.getData()
  }

/**
 * The function sets the latitude and longitude values for markers based on the model's id and
 * coordinates or default values.
 */
  getData(){
    this.markers = [];
    if(this.model.id) {
      this.markers.push({
        lat: Number(this.model.latitude),
        lng: Number(this.model.longitude),
        draggable: true,
      });

      this.lat = Number(this.model.latitude)
      this.lng = Number(this.model.longitude)
    }else{
      this.markers.push({
        lat: Number(appConstant.LAT),
        lng: Number(appConstant.LONG),
        draggable: true,
      });

      this.lat = Number(appConstant.LAT)
      this.lng = Number(appConstant.LONG)
    }
  }

  getCountries() {
    this.get(`${environment.urls.country.all}`).subscribe((res)=>{
      if(res.status)
        this.countries = res.items['items'] as any[]
    })
  }

  onSelect(val){
    this.model.country_id = val
  }


/**
 * The function saves form data and uploads an image if it exists, otherwise it displays an error
 * message.
 * @returns It depends on the execution flow. If the condition `if (!this.model.image ||
 * this.model.image == "")` is true, then the function will return without executing the rest of the
 * code and display an error message using `toastr`. Otherwise, if `imgUploaded` is true, it will
 * upload the image and append the image name to `formData` before calling `addEditBranch()`.
 */
  save() {   
    this.formData = new FormData();
    let formData2 = new FormData();
    if (!this.model.image || this.model.image == "") {
      formData2 = new FormData();
      this.toastr.error(this.translate.instant("VALIDATION.image"));
      return;
    }

    if(this.imgUploaded) {
      formData2.append("image", this.model.image);
      this.add(environment.urls.upload, formData2, true, false).subscribe((res)=>{ 
        if(res.status){
          let item = res.items as any;
          this.formData.append("image", item.image_name);
          this.addEditBranch();        
        }
      })
    }else{
      this.addEditBranch();
    }
  }

/**
 * The function adds or edits a branch by appending form data and making a POST request to a specified
 * URL.
 */
  addEditBranch() {
    var url = "";
    this.formData.append("description_ar", this.model.description_ar);
    this.formData.append("description_en", this.model.description_en);
    this.formData.append("country_id", this.model.country_id);
    this.formData.append("latitude", String(this.model.latitude));
    this.formData.append("longitude", String(this.model.longitude));
    // this.formData.append("is_activer", String(this.model.is_active));

    if (this.model.id) {
        this.formData.append("id", this.model.id);
        url = `${environment.urls.branch.update}${this.model.id}`
    } else {
        url = `${environment.urls.branch.create}`
    }

    this.add(url, this.formData, true).subscribe((res)=>{ 
      if(res.status){
        this.action.emit(res.status)
        this.activeModal.close()
      }
    })
  }


/**
 * This function processes an uploaded image file and checks if it is a valid image type.
 * @param event - The event parameter is an object that represents the event that triggered the
 * function. In this case, it is the event of a file being selected by the user through an input
 * element.
 */
  processImage(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        const file: File = event.target.files[0]
        if(file.type.includes("image")){
          this.model.image = file as any;
          this.imgUploaded = true;
        }else{
          this.toastr.error(this.translate.instant('VALIDATION.invalid_image'))
        }
      };
    }else{
      this.model.image = "" 
      this.imgUploaded = false;

    }
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
		
	}

  clickedMarker(label: string, index: number) {
	
	}

/**
 * The function sets the latitude and longitude of a marker on a map based on a mouse click event.
 * @param {MouseEvent}  -  is an object that contains information about the event that
 * triggered the function. In this case, it is a MouseEvent, which means that it was triggered by a
 * mouse click on the map. The  object contains information about the coordinates of the click
 * (lat and lng), as well as other
 */
	mapClicked($event: MouseEvent) {
		this.markers = [];
		this.markers.push({
			lat: $event.coords.lat,
			lng: $event.coords.lng,
			draggable: true,
		});

    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;

    this.model.latitude = $event.coords.lat;
    this.model.longitude = $event.coords.lng;
	}
}

interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}


