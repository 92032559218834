import { Component, OnDestroy, OnInit } from '@angular/core';
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { catchError, finalize, map } from "rxjs/operators";
import { of, Subscription } from "rxjs";
import { TableResponseModel, response, generalModel } from '../../model/generalModel'
import { ConstantServiceWrapper } from 'src/app/service/ConstantServiceWrapper.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
declare var require;
const Swal = require("sweetalert2");

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {
  momentFormat = new MomentDateFormatter();
  currency = environment.currency
  public subscriptions: Subscription[] = [];
  showLoader = false
  isShowValidation = false
  page = 1;
  limit = 10;
  collectionCount = 0;
  totalPages = 0;
  totalElements = 0
  size = 5
  constructor(
    public service:ConstantServiceWrapper, 
    public toastr: ToastrService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal
    ) {
      this.translate.instant
     }

    ngOnInit(): void {
    }

    ngOnDestroy() {
      this.subscriptions.forEach((sb) => sb.unsubscribe());
    }
    
    /**
     * This function returns an Observable that makes an HTTP GET request to a specified path and returns a
     * TableResponseModel of generalModel.
     * @param {string} path - A string representing the endpoint URL to make a GET request to.
     * @returns An Observable of type TableResponseModel<generalModel> is being returned.
     */
    get(path:string): Observable<TableResponseModel<generalModel>>{
      this.service.setLoading(true)
      return new Observable<TableResponseModel<generalModel>>((observer) => {
        this.service.GET(path)
        .subscribe((res:HttpResponse<TableResponseModel<generalModel>>)=>{
          this.service.setLoading(false)
          if(res.status === 200) {
            observer.next(res.body);
          }
        });
      });
    }
    
    /**
     * This function edits a model at a specified path and returns an observable with a table response
     * model.
     * @param path - The API endpoint path where the PUT request will be sent.
     * @param {any} model - The `model` parameter is of type `any` and represents the data that will be
     * sent in the PUT request to the specified `path`. It could be any type of data, depending on the
     * requirements of the API endpoint being called.
     * @param {Boolean} multipart - A boolean value that indicates whether the request should be sent as a
     * multipart/form-data request. This is typically used when uploading files or other binary data. If
     * set to true, the request body will be formatted as a multipart/form-data request, otherwise it will
     * be sent as a regular JSON request.
     * @returns An Observable of type TableResponseModel<any> is being returned.
     */
    edit(path, model: any, multipart: Boolean): Observable<TableResponseModel<any>>{
      this.service.setLoading(true)
      this.showLoader = true
      return new Observable<TableResponseModel<any>>((observer) => {
        this.service.PUT(path,model,multipart)
        .subscribe((res:HttpResponse<TableResponseModel<any>>)=>{
          this.service.setLoading(false)
          this.showLoader = false
          if(res.status === 200){
            this.toastr.success(res.body.message)
            observer.next(res.body)
          }
        });
      })
    }
  
    /**
     * This is a TypeScript function that sends a POST request to a specified path with a model, and
     * returns an Observable that emits a TableResponseModel upon success. It also displays a success
     * message using Toastr and sets a loading flag.
     * @param path - The API endpoint path where the data will be sent.
     * @param {any} model - The model parameter is of type "any" and represents the data that will be sent
     * in the HTTP POST request. It could be any type of data, such as an object or an array.
     * @param {Boolean} multipart - A boolean value indicating whether the request contains
     * multipart/form-data. This is commonly used when uploading files or other binary data. If set to
     * true, the request body will be formatted as multipart/form-data. If set to false, the request body
     * will be formatted as application/json or application/x-www-form-urlencoded
     * @param {Boolean} [ishow=true] - The "ishow" parameter is a boolean value that determines whether or
     * not to display a success message using the Toastr library after the API call is successful. If it is
     * set to true, a success message will be displayed. If it is set to false, no message will be
     * displayed.
     * @returns An Observable of type TableResponseModel<any> is being returned.
     */
    add(path, model: any, multipart: Boolean, ishow:Boolean = true): Observable<TableResponseModel<any>> {
      this.service.setLoading(true)
      this.showLoader = true
      return new Observable<TableResponseModel<any>>((observer) => {
        this.service.POST(path,model, multipart)
        .subscribe((res:HttpResponse<TableResponseModel<any>>)=>{
          this.service.setLoading(false)
          this.showLoader = false
          if(res.status === 200){
            if(ishow){
              this.toastr.success(res.body.message)
            }
            observer.next(res.body)
          }
        });
      })
    }
   
    /**
     * This function removes an item from a table using an HTTP DELETE request and returns an Observable
     * with the response.
     * @param path - The path parameter is a string that represents the endpoint or URL of the API that the
     * method is calling. It is used to construct the full URL by appending the id parameter to it.
     * @param {any} id - The `id` parameter is of type `any` and is used to identify the specific item that
     * needs to be removed from the table. It is concatenated with the `path` parameter to form the
     * complete URL path for the DELETE request.
     * @returns An Observable of type TableResponseModel<any> is being returned.
     */
    remove(path, id:any): Observable<TableResponseModel<any>>{
      var _path = `${path}${id}`
      this.service.setLoading(true)
      this.showLoader = true
      return new Observable<TableResponseModel<any>>((observer) => {
        this.service.DELETE(_path)
        .subscribe((res:HttpResponse<TableResponseModel<any>>)=>{
          this.service.setLoading(false)
          this.showLoader = false
          if(res.status === 200){
            this.toastr.success(res.body.message)
            observer.next(res.body)
          }
        });
      })
    }


    /**
     * The function displays a warning message with a confirmation dialog box and returns a promise that
     * resolves with the user's choice.
     * @returns A Promise object is being returned.
     */
    alertWithMessageAndConfirm(){
      const warning_key = this.translate.instant('GENERAL.warning_key')
      const sure = this.translate.instant('GENERAL.sure')
      const yes = this.translate.instant('GENERAL.YES')
      const no = this.translate.instant('GENERAL.NO')
      return new Promise(function (resolve, reject) {
        Swal.fire({
          title: warning_key,
          text: sure,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: yes,
          cancelButtonText: no
          // confirmButtonColor: "#3085d6",
          // cancelButtonColor: "#d33",
          // animation: false,
          // customClass: "animated tada",
        }).then((result) => {
          resolve(result.value);
        }).catch((err)=>{
          reject(err);
        })
      })
    }
}


import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DatePipe } from '@angular/common';

export class MomentDateFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";

  parse(value: string): NgbDateStruct {
    let datePipe = new DatePipe("en-US");

    let returnVal: NgbDateStruct;
    if (!value) {
      returnVal = null;
    } else {
      try {
        let dateParts = datePipe.transform(value, "M-d-y").split("-");
        returnVal = {
          year: parseInt(dateParts[2]),
          month: parseInt(dateParts[0]),
          day: parseInt(dateParts[1]),
        };
      } catch (e) {
        returnVal = null;
      }
    }
    return returnVal;
  }

  // parse(value: string): NgbDateStruct {
  //   if (value) {
  //     value = value.trim();
  //     let mdt = moment(value, this.DT_FORMAT);
  //   }
  //   return {} as any;
  // }

  format(date: NgbDateStruct): string {
    if (!date) return "";
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return "";
    return mdt.format(this.DT_FORMAT);
  }
}
