import { environment } from "../../environments/environment";
import { TranslateService } from '@ngx-translate/core';

export class appConstant {
  public static SUM = "sum";
  public static ITEMS = "items";
  public static STATUS = "status";
  public static MESSAGE = "message";
  public static PAGINATION = "pagenation";
  public static STATUS_CODE = "status_code";
  public static BASE_URL = environment.base_url;
  public static LAT =  44.500000;
  public static LONG = -89.500000;
}



export enum UserType {
  ADMIN = "ADMIN",
  DESIGNER = "DESIGNER",
  IMPLEMENTER = "IMPLEMENTER",
  STORE = "STORE",
}

export class Constant{
  public static benifit = {
    simple_tools:"simple_tools",
    what_you_will_find: "what_you_will_find",
    help_around_clock: "help_around_clock",
    simplicity_and_transparency: "simplicity_and_transparency",
    advantages_of_simplicity: "advantages_of_simplicity",
    feature_joining_us: "feature_joining_us",
    vendors_stories: "vendors_stories",
    create_your_vendor: "create_your_vendor",
    payment_methods: "payment_methods",
    what_can_you_sell: "what_can_you_sell"
  }

  public static ADVSTYPE = {
    banner:"banner",
    guide: "guide"
  }

  public static isSupportAr='false';

  public static StockTypes = [
    {id:"in_stock" ,      titleAR:"متوفر", titleEN:"In stock"},
    {id:"out_of_stock" , titleAR:"نفذ", titleEN:"Out of stock"},
    {id:"low_of_stock" , titleAR:"على وشك النفاذ", titleEN:"Low of stock"},
  ]

  public static langs = [
    {id:"1" , key:"ar"},
    {id:"2" , key:"en"},
  ];

  



  // expenses_section
  // tool_section
  // create_your_vendor_section
  // sale_section
  // help_and_support_section
  // stories_section
  // categories_section
  
  public static cms_actions = [
    {id:"tool_section" ,     titleAR:"قسم الاداوات", titleEN:"tool section"},
    {id:"expenses_section" ,     titleAR:"قسم المصروفاف", titleEN:"expenses section"},
    {id:"sale_section" , titleAR:"قسم البيع", titleEN:"sale section"},
    {id:"create_your_vendor_section" , titleAR:"قسم انشاء متجر", titleEN:"create your vendor section"},
    {id:"help_and_support_section" , titleAR:"فسم المساعدة والدعم ", titleEN:"help and support section"},
    {id:"stories_section" , titleAR:"قسم القصص", titleEN:"stories section"},
    {id:"categories_section" , titleAR:"فسم التصنيفات", titleEN:"categories section"},
  ]

  public static StatusLogs = {
    add:"add",
    subtract: "subtract",
    returned: "returned"
  }

  public static StatusLogsList = [
    {id:"add" , titleAR:"مضاف", titleEN:"Adding"},
    {id:"subtract" , titleAR:"مخصوم", titleEN:"Subtract"},
    {id:"returned" , titleAR:"مرجع", titleEN:"Returned"},
  ];

  public static ABOUT_TYPES = [
    {id:"about", titleEN:"about", titleAR:"من نحن"},
    {id:"why_buy_from_us", titleEN:"why buy from us", titleAR:"لماذا تبيع معنا"},
    {id:"why_sell_with_us", titleEN:"why sell with us", titleAR: "لماذا تشتري منا"},
    {id:"vision", titleEN:"vision", titleAR: "الرؤية"}
  ];

  public static StatusArrayList = {
    under_review:"under_review",
    acceptable: "acceptable",
    unacceptable: "unacceptable"
  }

  public static WithdrawStatusArrayList = {
    pending:"pending",
    // under_review: "under_review",
    acceptable: "acceptable",
    unacceptable: "unacceptable"
  }

  public static StatusList = [
    {id:"under_review" , titleAR:"تحت المراجعة", titleEN:"Under review"},
    {id:"acceptable" , titleAR:"تمت الموافقة", titleEN:"Accepted"},
    {id:"unacceptable" , titleAR:"مرفوض", titleEN:"Rejected"}
  ];

  //'pending','under_review','unacceptable','acceptable'
  public static WithdrawStatusList = [
    {id:"pending",      titleAR:"معلق", titleEN:"New"},
    {id:"acceptable",   titleAR:"مقبول", titleEN:"Approved"},
    {id:"canceled",     titleAR:"ملغي", titleEN:"Cancel"},
    {id:"unacceptable", titleAR:"مرفوض", titleEN:"Not Approved"},
  ];

  public static ORDER_STATUS_ARRAY = [
    // {id:"new" , titleAR:"جديد", titleEN:"New"},
    {id:"pending" , titleAR:"بانتظار الموافقة", titleEN:"Pending"},
    {id:"under_processing" , titleAR:"جاري التنفيذ", titleEN:"On progress"},
    {id:"complete" , titleAR:"مكتمل", titleEN:"Completed"},
    {id:"canceled" , titleAR:"ملغي", titleEN:"Canceled"},
    {id:"canceled_by_user" , titleAR:"ملغي من العميل", titleEN:"Canceled by user"},
    {id:"failed" , titleAR:"فشل العملية", titleEN:"Failed"},
    {id:"retriever" , titleAR:"", titleEN:"Retriever"},
  ];

  public static ORDER_STATUS_SUMMERY_ARRAY = [
    {id:"" , titleAR:"الكل", titleEN:"All"},
    // {id:"new" , titleAR:"جديد", titleEN:"New"},
    {id:"pending" , titleAR:"بانتظار الموافقة", titleEN:"Pending"},
    {id:"under_processing" , titleAR:"جاري التنفيذ", titleEN:"On progress"},
    {id:"complete" , titleAR:"مكتمل", titleEN:"Completed"},
    {id:"canceled" , titleAR:"ملغي", titleEN:"Canceled"},
    {id:"canceled_by_user" , titleAR:"ملغي من العميل", titleEN:"Canceled by user"},
    {id:"failed" , titleAR:"فشل العملية", titleEN:"Failed"},
    {id:"retriever" , titleAR:"", titleEN:"Retriever"},
  ];

  public static PRODUCT_MODE = {
    PENDING: "pending",
    APPROVED: "approved",
    NOTAPPROVED:  "not_approved",
    UNDERREVIEW: "under_review"
  }
  
  public static PRODUCT_TYPES = {
    SIMPLE: "simple",
    VARIABLE: "variable",
  }

  public static ORDER_STATUS = {
      // New: "new",
      Pending: "pending",
      Progress: "under_processing",
      Finished: "complete",
      Canceled: "canceled",
      UserCanceled: "canceled_by_user",
      Retriever:"retriever",
      Failed:"failed",
  }

  public static AddressType = {
    Shipping: "shipping",
    Billing: "billing",
  }

  public static MethodType = {
    Shipping: "shipping",
    Billing: "invoice",
  }

  public static STATIC_PAGES = {
      PRIVACY: "PrivacyPolicy",
      TERMS: "TermsOfUse",
      SELLER_BUYER: "SellerAndBuyer",
  }

  public static PAYMENT_TYPE = {
    DirectBank: "direct_bank_transfer",
    ApplePay: "apple_pay",
    CreditCard: "credit_cards"
  }

  public static NotificationTypes = {
    GENERAL: "general",
    ORDER: "orders",
    PRODUCT: "products",
    VENDOR: "vendors_verifications_requests",
    WITHDRAW: "withdrawal_requests",
    MIN: "minimum_quantity_products",
  }

  public static NotificationCategory = {
    all: "all",
    vendors: "all_vendors",
    users: "all_users",
  }

  public static UserNotificationTypes = [
    //all,all_vendors,specific_users,specific_vedors,specific_countries
    {id:"all", titleAR:"الكل", titleEN:'All'},
    {id:"all_vendors", titleAR:"كل المزودين", titleEN:'All vendors'},
    {id:"all_users", titleAR:"كل المستخدمين", titleEN:'All users'},
    // {id:"specific_vendors", titleAR:"مزودين محددين", titleEN:'Specific vendors'},
    // {id:"specific_users", titleAR:"مستخدمين محددين", titleEN:'Specific users'},
  ]

  public static AdbsType = [
    {id:'slider', titleAR:"سلايدر", titleEN:'Slider'},
    {id:'banner', titleAR:"بانر", titleEN:'Banner'}
  ]

  public static AdbsActionType = [
    {id:"null", titleAR:"بدون توجيه", titleEN:'without redirect'},
    {id:'Category', titleAR:"التصنيف الرئيسي", titleEN:'Category'},
    {id:'SubCategory', titleAR:"التصنيف الفرعي", titleEN:'SubCategory'},
    {id:'Provider', titleAR:"متجر", titleEN:'Provider'}
  ]

  public static ReviewStatus = {
    accepted: "accepted",
    canceled: "canceled",
    pending: "pending"
  }

  public static SOCIAL_TYPE = {
    YOUTUBE: "youtube",
    FACEBOOK: "facebook",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
  }

}

export class AdminRoles {
  public static show_general_settings = "show_general_settings";
  public static show_categories = "show_categories";
  public static show_banks = "show_banks";
  public static show_social_links = "show_social_links";
  public static show_static_pages = "show_static_pages";
  public static show_faqs = "show_faqs";
  public static show_contact_us_messages = "show_contact_us_messages";
  public static show_banners =  "show_banners";
  
  public static show_branches = "show_branches";
  
  public static show_coupons = "show_coupons";

  public static show_admins = "show_admins";
  public static show_roles = "show_roles";
  
  public static show_clients = "show_clients";
  public static show_vendors = "show_vendors";

  public static show_open_vendor_requests = "show_open_vendor_requests";
  
  public static show_products = "show_products";
  public static show_products_in_stock = "show_products_in_stock";
  
  public static show_orders = "show_orders";
  
  public static show_withdraw_request = "show_withdraw_request";
  
  public static show_vendor_reviews = "show_vendor_reviews";
  public static show_product_reviews = "show_product_reviews";

  public static show_sales_report = "show_sales_report";
  public static show_earning_report = "show_earning_report";
  public static show_finance_report = "show_finance_report";

  public static show_notifications = "show_notifications";  
 
  public static show_feature_category = "show_feature°category";  
  public static show_feature_join_us = "show_feature_join_us";  
  public static show_help_around_clock = "show_help_around_clock";  
  public static show_simple_tools = "show_simple_tools";  
  public static show_simplicity_and_transparency = "show_simplicity_and_transparency";  
  public static show_story = "show_vendor_stories";  
  public static show_titles = "show_section_texts";  
  public static show_what_you_will_find = "show_what_you_will_find";  
  public static show_advantages_of_simplicity = "show_advantages_of_simplicity";  
  public static show_banner_page = "show_banner_beginner_guide_page";  

  public static All_Roles = [
    AdminRoles.show_general_settings,
    AdminRoles.show_general_settings,
    AdminRoles.show_categories,
    AdminRoles.show_banks,
    AdminRoles.show_social_links,
    AdminRoles.show_static_pages,
    AdminRoles.show_faqs,
    AdminRoles.show_contact_us_messages,
    AdminRoles.show_banners,
  
    AdminRoles.show_branches,
    AdminRoles.show_coupons,
    AdminRoles.show_admins,
    AdminRoles.show_roles,
    AdminRoles.show_clients,
    AdminRoles.show_vendors,
    AdminRoles.show_open_vendor_requests,

    AdminRoles.show_products,
    AdminRoles.show_products_in_stock,
    AdminRoles.show_orders,
    AdminRoles.show_withdraw_request,

    AdminRoles.show_vendor_reviews,
    AdminRoles.show_product_reviews,

    AdminRoles.show_sales_report,
    AdminRoles.show_earning_report,
    AdminRoles.show_finance_report,

    AdminRoles.show_notifications,

    AdminRoles.show_feature_category,
    AdminRoles.show_feature_join_us,
    AdminRoles.show_help_around_clock,
    AdminRoles.show_simple_tools,
    AdminRoles.show_simplicity_and_transparency,
    AdminRoles.show_story,
    AdminRoles.show_titles,

    AdminRoles.show_what_you_will_find,
    AdminRoles.show_advantages_of_simplicity,
    AdminRoles.show_banner_page,

  ]

  public static All_Pages = [
    {
      route: "/constant/subcategory",
      permession: AdminRoles.show_categories,
      url:"/constant/subcategory"
    },
    {
      route: "/constant/settings",
      permession: AdminRoles.show_general_settings,
      url:"/constant/settings"
    },
    {
      route: "/constant/category",
      permession: AdminRoles.show_categories,
      url:"/constant/category",
    },
    {
      route: "/constant/bank",
      permession: AdminRoles.show_banks,
      url: "/constant/bank",
    },
    {
      route: "/constant/social",
      permession: AdminRoles.show_social_links,
      url: "/constant/social",
    },
    {
      route: "/constant/staticpage",
      permession: AdminRoles.show_static_pages,
      url: "/constant/staticpage",
    },
    {
      route: "/constant/staticpage",
      permession: AdminRoles.show_static_pages,
      url: "/constant/staticpage/details/",
    },
    {
      route: "/constant/faq",
      permession: AdminRoles.show_faqs,
      url: "/constant/faq",
    },
    {
      route: "/constant/contact",
      permession: AdminRoles.show_contact_us_messages,
      url: "/constant/contact",
    },
    {
      route: "/constant/slider",
      permession: AdminRoles.show_banners,
      url: "/constant/slider",
    },
    {
      route: "/constant/branch",
      permession: AdminRoles.show_branches,
      url: "/constant/branch",
    },
    {
      route:"/constant/branch/details/",
      permession: AdminRoles.show_branches,
      url: "/constant/branch/details/",
    },
    {
      route: "/coupon/list",
      permession: AdminRoles.show_coupons,
      url: "/coupon/list",
    },
    {
      route: "/continent-sliders/list",
      permession: AdminRoles.show_coupons,
      url: "/continent-sliders/list",
    },
    {
      route: "/coupon/details",
      permession: AdminRoles.show_coupons,
      url: "/coupon/details/",
    },
    {
      route: "/users/admin/list",
      permession: AdminRoles.show_admins,
      url: "/users/admin/list",
    },
    {
      route: "//users/admin/add",
      permession: AdminRoles.show_admins,
      url: "/users/admin/add/",
    },
    {
      route: "/constant/roles",
      permession: AdminRoles.show_roles,
      url: "/constant/roles",
    },
    {
      route: "/users/clients",
      permession: AdminRoles.show_clients,
      url:"users/clients"
    },
    {
      route: "/users/brands",
      permession: AdminRoles.show_vendors,
      url:"/users/brands/"
    },
    {
      route: "/users/brands/add",
      permession: AdminRoles.show_vendors,
      url:"/users/brands/add/"
    },
    {
      route: "/users/brands/details",
      permession: AdminRoles.show_vendors,
      url:"/users/brands/details/"
    },
    {
      route: "/users/request",
      permession: AdminRoles.show_open_vendor_requests,
      url:"/users/request"
    },
    {
      route: "/products/list",
      permession: AdminRoles.show_products,
      url:"/products/list"
    },
    {
      route: "/products/limited",
      permession: AdminRoles.show_products_in_stock,
      url:"/products/limited"
    },
    {
      route: "/products/details/",
      permession: AdminRoles.show_products,
      url:"/products/details/"
    },
    {
      route: "/orders/list",
      permession: AdminRoles.show_orders,
      url: "/orders/list",
    },
    {
      route: "/orders/orderDetails/",
      permession: AdminRoles.show_orders,
      url: "/orders/orderDetails/",
    },
    {
      route: "/withdraw/list",
      permession: AdminRoles.show_withdraw_request,
      url: "/withdraw/list",
    },
    {
      route: "/withdraw/details/",
      permession: AdminRoles.show_withdraw_request,
      url: "/withdraw/details/",
    },
    {
      route: "/review/brand",
      permession: AdminRoles.show_vendor_reviews,
      url:  "/review/brand",
    },
    {
      route: "/review/product",
      permession: AdminRoles.show_product_reviews,
      url:  "/review/product",
    },
    {
      route: "/report/sales",
      permession: AdminRoles.show_sales_report,
      url:  "/report/sales",
    },
    {
      route: "/report/earning",
      permession: AdminRoles.show_earning_report,
      url:  "/report/earning",
    },
    {
      route: "/report/finance",
      permession: AdminRoles.show_finance_report,
      url:  "/report/finance",
    },
    {
      route: "/notification/all",
      permession: AdminRoles.show_notifications,
      url:"/notification/all"
    },

    {
      route: "/guide/titles",
      permession: AdminRoles.show_titles,
      url:"/guide/titles"
    },
    {
      route: "/guide/slider",
      permession: AdminRoles.show_banner_page,
      url:"/guide/slider"
    },
    {
      route: "/guide/story",
      permession: AdminRoles.show_story,
      url:"/guide/story"
    },
    {
      route: "/guide/feature",
      permession: AdminRoles.show_feature_category,
      url:"/guide/feature"
    },
    {
      route: "/guide/join",
      permession: AdminRoles.show_feature_join_us,
      url:"/guide/join"
    },
    {
      route: "/guide/tool",
      permession: AdminRoles.show_simple_tools,
      url:"/guide/tool"
    },
    {
      route: "/guide/advantages_of_simplicity",
      permession: AdminRoles.show_advantages_of_simplicity,
      url:"/guide/advantages_of_simplicity"
    },
    {
      route: "/guide/simplicity_and_transparency",
      permession: AdminRoles.show_simplicity_and_transparency,
      url:"/guide/simplicity_and_transparency"
    },
    {
      route: "/guide/what_will_you_find",
      permession: AdminRoles.show_what_you_will_find,
      url:"/guide/what_will_you_find"
    },
    {
      route: "/guide/help",
      permession: AdminRoles.show_help_around_clock,
      url:"/guide/help"
    },
  ]
}