import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category, sub_category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-reason-order',
  templateUrl: './reason-order.component.html',
  styleUrls: ['./reason-order.component.scss']
})
export class ReasonOrderComponent extends BaseComponent implements OnInit {
  @Input() ref:any;
  @Input() order_id:any;
  @Input() status:any;
  @Output() public action= new EventEmitter();
  message=""

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }


  ngOnInit(): void {
  }


/**
 * The "change" function updates the status of an order to "Canceled" and includes a reason for
 * refusal.
 */
  change() {
    let body = { status: Constant.ORDER_STATUS.Canceled, reason_refuse: this.message }
    this.add(`${environment.urls.order.update}${this.order_id}`, body, false).subscribe(res=>{
      if(res.status){
        this.action.emit(res.status)
        this.activeModal.close()
      }
    })
  }
}
