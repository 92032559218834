import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { appConstant } from "./appConstant";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { catchError, finalize, map } from "rxjs/operators";
import { of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as CryptoJS from "crypto-js";
import { TableResponseModel } from "../model/generalModel";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ConstantServiceWrapper {
  public change: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient, 
    private toastr: ToastrService , 
    private translate: TranslateService,
    private router: Router) {}

  public setLoading(value) {
    this.change.emit(value);
  }

  saveToken(token){
    let cryptoToken = CryptoJS.AES.encrypt(token, environment.key).toString();
    localStorage.setItem("token", cryptoToken);
  }
  
  getMyToken() {
    if (localStorage.getItem("token")) {
      // let decryptedData = localStorage.getItem("token")
      let decryptToken = CryptoJS.AES.decrypt(localStorage.getItem("token"),  environment.key);
      var decryptedData = decryptToken.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }else{
      return ""
    }
  }

  getCurrentLanguage() {
    return localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : "en";
  }
/**
 * This function returns a HttpHeaders object with authorization, language, and access control headers
 * for a delete request.
 * @returns The function `getDeleteHeaders()` returns an instance of `HttpHeaders` with authorization,
 * language, and access control headers set.
 */

/**
 * This function returns HTTP headers with authorization, language, and access control information for
 * a delete request.
 * @returns The function `getDeleteHeaders()` is returning an instance of `HttpHeaders` with some
 * headers set, including an authorization token, language, and access control origin.
 */
  getDeleteHeaders(){
    var headers = new HttpHeaders({
      // Content: "application/json",
      // Accept: "application/json",
      // "Content-Type": "application/json; charset=utf-8",
      'Authorization': "Bearer "+this.getMyToken(),
      "lang": this.getCurrentLanguage(),
      "Access-Control-Allow-Origin":"*"
    });
    return headers;
  }


/**
 * This function returns a set of headers with various properties including authorization, language,
 * and content type.
 * @returns The function `getHeaders()` returns an instance of `HttpHeaders` with various headers
 * including `Content`, `Accept`, `Content-Type`, `Authorization`, `lang`, and
 * `Access-Control-Allow-Origin`.
 */
  getHeaders(){
    var headers = new HttpHeaders({
      Content: "application/json",
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      'Authorization': "Bearer "+this.getMyToken(),
      "lang": this.getCurrentLanguage(),
      "Access-Control-Allow-Origin":"*"
    });
    return headers;
  }

/**
 * This function returns a set of headers for a multipart/form-data request with authorization and
 * language information.
 * @returns The function `getMultipartHeaders()` is returning an instance of the `HttpHeaders` class
 * with several headers set, including `Content`, `Accept`, `Access-Control-Allow-Origin`,
 * `Authorization`, and `lang`.
 */
  getMultipartHeaders(){
    var headers = new HttpHeaders({
        Content: "multipart/form-data",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        'Authorization': "Bearer "+this.getMyToken(),
        "lang": this.getCurrentLanguage(),
      });
    return headers;
  }

/**
 * The function formats error messages from an HTTP response and displays them using Toastr.
 * @param {HttpErrorResponse} error - The error parameter is of type HttpErrorResponse, which is an
 * object that represents an HTTP response error from a server. It contains information such as the
 * status code, error message, and any additional error details.
 * @returns a Toastr notification with an error message. If the error object contains an "errors"
 * property with nested error messages, those messages are concatenated and displayed in the Toastr
 * notification. If the error object contains a "message" property, that message is displayed. If
 * neither of these properties are present, a default "Unknown" error message is displayed.
 */
  private formatErrors(error: HttpErrorResponse) {
    if(error.error?.errors) {
      var msg = ""
      let keys = Object.keys(error.error?.errors) as Array<any>;
      keys.forEach(element => {
        if(element.length > 0) {
          msg += error.error?.errors[element][0] + "\n"
        }
      });
      if(msg != "") {
        return this.toastr.error(msg);
      }
    }
    // if(error.error?.errors && error.error?.errors.image && error.error?.errors.image.length > 0){
    //   if (error.error?.errors.image.length > 0){
    //     return this.toastr.error(error.error?.errors.image[0]);
    //   }
    // }
    if(error.error?.message)
      return this.toastr.error(error.error?.message)
    else  return this.toastr.error(this.translate.instant("GENERAL.Unknown")) 
  }
  
  RefreshToken() {
    localStorage.removeItem("token");
    this.router.navigate(['/auth/login'])
    this.toastr.warning(this.translate.instant('Auth.EndSession'));
  }
 
  
/**
 * This function sends a GET request to a specified path and handles errors, including refreshing the
 * token if the status is 401.
 * @param path - The path parameter is a string that represents the endpoint or resource that the HTTP
 * GET request will be sent to. It is appended to the base URL defined in the environment file to form
 * the complete URL for the request.
 * @returns The `GET` method is returning an Observable that makes an HTTP GET request to a specified
 * path using the Angular `HttpClient`. The response is then piped through a series of operators,
 * including `catchError` and `map`, before being returned. The `catchError` operator handles any
 * errors that may occur during the request, while the `map` operator transforms the response data
 * before returning it.
 */
  GET(path) {
   return this.http.get<any>(`${environment.base_url}${path}`,{ headers: this.getHeaders(), observe: 'response' })
      .pipe(
        catchError(err => {
          // this._errorMessage.next(err);
          if(err && err['status'] && err['status'] == 401){
            //refresh token 
            setTimeout(() => {
              this.RefreshToken()
            }, 300);
          }else{
            this.formatErrors(err)
            return of({ id: [] });
          }
        }),
        map(data => { 
          //this._isLoadingSingle$.next(false); 
          return data
        })
    )
    // return this.http.get(appConstant.BASE_URL + path);
  }
  
/**
 * This is a function that sends a PUT request to a specified path with an item and optional multipart
 * flag, handles errors and returns the response.
 * @param {string} path - A string representing the endpoint URL to which the PUT request will be sent.
 * @param {any} item - The data that needs to be updated in the backend.
 * @param {Boolean} multipart - A boolean value that indicates whether the request should be sent as a
 * multipart/form-data request. If true, the request will be sent as a multipart/form-data request,
 * otherwise it will be sent as a regular JSON request.
 * @returns This function returns an Observable of type `any` that makes an HTTP PUT request to a
 * specified path with a given item. The `multipart` parameter is used to determine whether to use
 * multipart headers or regular headers. The function also handles errors and refreshes the token if
 * the error status is 401. Finally, it maps the response data and returns it.
 */
  PUT(path:string,item: any, multipart: Boolean){
    return this.http.put<any>(`${environment.base_url}${path}`, item, { headers: multipart?   this.getMultipartHeaders() :this.getHeaders(), observe: 'response' })
      .pipe(
        catchError(err => {
          if(err && err['status'] && err['status'] == 401){
            //refresh token 
            setTimeout(() => {
              this.RefreshToken()
            }, 300);
          }else{
            this.formatErrors(err)
            return of({ id: undefined });
          }
        }),
        map(data => { 
          // this._isLoadingAddEdit$.next(false); 
          return data 
        })
    )
  }
  
/**
 * This is a TypeScript function that sends a POST request with optional multipart data and handles
 * errors, including refreshing the token if necessary.
 * @param {string} path - A string representing the endpoint URL to which the HTTP POST request will be
 * sent.
 * @param {any} item - The data to be sent in the HTTP POST request.
 * @param {Boolean} multipart - A boolean value indicating whether the request should be sent as
 * multipart/form-data or not. If true, the request will be sent as a multipart/form-data request,
 * which is commonly used for uploading files or other binary data. If false, the request will be sent
 * as a regular JSON request.
 * @returns an Observable of type `any` that makes an HTTP POST request to a specified path with a
 * given item and a boolean flag indicating whether the request is a multipart request or not. The
 * function also handles errors and returns an Observable of an object with an `id` property.
 */
  POST(path:string,item: any, multipart: Boolean){
    return this.http.post<any>(`${ environment.base_url }${ path }`, item, { headers: multipart ? this.getMultipartHeaders() : this.getHeaders(), observe: 'response' })
      .pipe(
        catchError(err => {
          if(!path.includes('signin')) {
            if(err && err['status'] && err['status'] == 401){
              //refresh token 
              if(!path.includes('signin')){
                setTimeout(() => {
                  this.RefreshToken()
                }, 300);
              }
            }else{
              this.formatErrors(err)
              return of({ id: undefined });
            }
          }else{
            this.formatErrors(err)
            return of({ id: undefined });
          }
        }),
        map(data => { 
           return data 
        })
    )
  }

/**
 * This is a TypeScript function that sends a DELETE request to a specified path and handles errors,
 * including refreshing the token if the status is 401.
 * @param {string} path - The path parameter is a string that represents the endpoint URL that the
 * DELETE request will be sent to. It is concatenated with the base URL defined in the environment file
 * to form the complete URL.
 * @returns This function returns an Observable that makes an HTTP DELETE request to a specified path
 * using the Angular HttpClient. The response is expected to be of type `any`. The function also
 * handles errors by checking if the error status is 401 (Unauthorized) and if so, it attempts to
 * refresh the authentication token. If the error is not a 401 error, it formats the error and returns
 * an Observable of an
 */
  DELETE(path:string){
    return this.http.delete<any>(`${environment.base_url}${path}`, { headers:this.getDeleteHeaders(), observe: 'response' })
      .pipe(
        catchError(err => {
          if(err && err['status'] && err['status'] == 401){
            //refresh token 
            setTimeout(() => {
              this.RefreshToken()
            }, 300);
          }else{
            this.formatErrors(err)
            return of({ id: undefined });
          }
        }),
        map(data => { return data })
    )
  }

  // downloadFile(data) {
  //   const blob = new Blob([data], { type: 'text/xlsx' });
  //   const url= window.URL.createObjectURL(blob);
  //   window.open(url);
  // }

/**
 * This function downloads a file from a given URL using the HTTP module in TypeScript.
 * @param targetUrl - The URL of the file that needs to be downloaded.
 */
  async downloadFile(targetUrl){
    this.http.get(targetUrl,{})
        .subscribe(async (res:Response)=>{
          var a = document.createElement("a");
          a.href = URL.createObjectURL(await res.blob());
          a.download = 'fileName';
          // start download
          a.click();
        })
  }
}
