import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbRatingConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuickViewComponent implements OnInit {
  @Input() productDetail: any;

  public counter: number = 1;
  public detailCnt = [];
  public slidesPerPage = 4;

  public increment() {
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  constructor(private router: Router, private route: ActivatedRoute, config: NgbRatingConfig, private ngb: NgbModal) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ngb.dismissAll();
      }
    });
  }

  public addToCart(product: any, quantity) {

  }

  public buyNow(product: any, quantity) {
    
  }

  ngOnInit() {
 
  }

}
