import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-static-page-edit',
  templateUrl: './static-page-edit.component.html',
  styleUrls: ['./static-page-edit.component.scss']
})
export class StaticPageEditComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();

  types = [];

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    this.open(this.model);
  }
  
 /**
  * The function opens a category model by either retrieving it from the server or creating a new one.
  * @param model - The `model` parameter is an object that represents a category. It is used to
  * retrieve or create a category in the system. The `id` property of the `model` object is used to
  * identify an existing category that needs to be retrieved from the server. If the `id` property is
  */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.static.single}${model.id}`).subscribe((res)=>{
        if(res.status){
          let data = res.items as any;
          this.model = data as any;
        }
      })
    } else {
      this.model = new category()
    }
  }

/**
 * The function saves a model by sending a POST request to the server with the model data.
 */
  savemodel() {
    var url = this.model.id ? `${environment.urls.static.update}${this.model.id}` : `${environment.urls.static.insert}`
    this.add(url, this.model, false).subscribe((res)=>{
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }
}
