import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from 'src/environments/environment';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-bank-add-edit',
  templateUrl: './bank-add-edit.component.html',
  styleUrls: ['./bank-add-edit.component.scss']
})

export class BankAddEditComponent extends BaseComponent implements OnInit {
  @Input() model:category = new category()
  @Output() public action= new EventEmitter();
  isSupportAr = Constant.isSupportAr

  styles = [];

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    this.open(this.model);
  }
  
/**
 * The function opens a model by either retrieving it from the server or creating a new instance if it
 * doesn't have an ID.
 * @param model - The `model` parameter is an object that represents a category. It is used to retrieve
 * or create a category in the bank. The `id` property of the `model` object is used to identify an
 * existing category in the bank. If the `id` property is present, the method retrieves
 */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.bank.single}${model.id}`).subscribe((res)=>{
        if(res.status){
          let data = res.items as any;
          this.model = data as category;
        }
      })
    } else {
      this.model = new category()
    }
  }

/**
 * The `saveAction()` function sends an HTTP request to update or insert data into a bank using the
 * `add()` method and emits the response status before closing a modal.
 */
  saveAction() {
    var url = this.model.id ? `${environment.urls.bank.update}${this.model.id}` : `${environment.urls.bank.insert}`
    this.add(url, JSON.stringify(this.model), false).subscribe((res)=>{
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }


}
