import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from 'src/environments/environment';
import { TreeviewItem } from 'ngx-treeview';

@Component({
  selector: 'app-role-add-edit',
  templateUrl: './role-add-edit.component.html',
  styleUrls: ['./role-add-edit.component.scss']
})

export class RoleAddEditComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();
  items = []
  permessions = []
  selectedPermession = []
  config = {
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500
  }

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

/**
 * The ngOnInit function calls the getPermessions function and opens the model.
 */
  ngOnInit(): void {
    this.getPermessions();
    this.open(this.model);
  }
  
/**
 * This function retrieves permissions data from a specified URL and stores it in a variable.
 */
  getPermessions(){
    this.get(`${environment.urls.roles.permession}`).subscribe((res)=>{
      if(res.status){
        let data = res.items as any;
        this.permessions = data['permissions'] as any;
        this.items = this.getData(this.permessions)
      }
    })
  }

/**
 * The "open" function retrieves data from an API endpoint and sets the model and items properties
 * based on the response.
 * @param model - The `model` parameter is an object that represents a role in the system. It has an
 * `id` property that is used to retrieve the role from the server. The `model` object also has a
 * `permissions` property that contains an object with various permissions for the role.
 */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.roles.single}${model.id}`).subscribe((res)=>{
        if(res.status){
          let data = res.items as any;
          this.model = data as any;
          this.items = this.getData(this.model.permissions)
          let keys =  Object.keys(this.model.permissions) as Array<string>
          var arr = []
          keys.forEach(key => {
            let value = this.model.permissions[key];
            if(value && value.items && value.items.length > 0){
              value.items.forEach( el => {
                arr.push(el)
              });
            }
          });
          this.selectedPermession = arr
        }
      })
    }
  }

/**
 * The function saves a role by sending a POST or PUT request to the server with the role's name and
 * selected permissions.
 */
  saveAction() {
    let body = {
      name: this.model.name,
      permissions: this.selectedPermession
    }
    var url = this.model.id ? `${environment.urls.roles.update}${this.model.id}` : `${environment.urls.roles.add}`
    this.add(url, JSON.stringify(body), false).subscribe((res)=>{
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }

/**
 * The function takes in a permission object, extracts its keys and values, creates an array of
 * TreeviewItem objects with children based on the values, and returns the array.
 * @param permession - It is a parameter that is being passed to the `getData` function. It is expected
 * to be an object that contains permissions data, with each key representing a category of permissions
 * and its corresponding value containing an array of items with their titles, keys, and check status.
 * The function then processes this data
 * @returns The function `getData` is returning an array of `TreeviewItem` objects. The `TreeviewItem`
 * objects are created based on the data passed as the `permession` parameter. The function loops
 * through the keys of the `permession` object, creates an array of `children` based on the `items`
 * property of the value of each key, and creates a `Treeview
 */
  getData(permession){
    var arr  = []
    let keys =  Object.keys(permession) as Array<string>
    keys.forEach(key => {
      let value = permession[key];
      var children = []
      value.items.forEach(element => {
        let obj = {
          text: element.title,
          value: element.key,
          checked: element.is_check ? element.is_check : false
        }
        children.push(obj)
      });
      const childrenObject = new TreeviewItem({ text: value.title, value: value.title, collapsed: false, children: children });
      arr.push(childrenObject)

    });
    return arr;

    // permession.forEach(element => {
    //   const childrenCategory = new TreeviewItem({
    //     text: 'Children', value: 1, collapsed: false, children: [
    //       { text: 'Baby 3-5', value: 11, checked: false },
    //       { text: 'Baby 6-8', value: 12, checked: false  },
    //       { text: 'Baby 9-12', value: 13, checked: true  }
    //     ]
    //   });
    // });
    // const childrenCategory = new TreeviewItem({
    //   text: 'Children', value: 1, collapsed: false, children: [
    //     { text: 'Baby 3-5', value: 11, checked: false },
    //     { text: 'Baby 6-8', value: 12, checked: false  },
    //     { text: 'Baby 9-12', value: 13, checked: true  }
    //   ]
    // });
    // const itCategory = new TreeviewItem({
      // text: 'Childrenxx', value: 2, collapsed: false, children: [
      //   { text: 'x 3-5', value: 10, checked: true },
      //   { text: 'x 6-8', value: 9 , checked: true},
      //   { text: 'x 9-12', value: 5 , checked: true}
      // ]
    // });
    // return [childrenCategory, itCategory,];
  }

/**
 * The function assigns the value of the event parameter to the selectedPermession variable.
 * @param event - The event parameter is an object that represents the change event that occurred. It
 * contains information about the event, such as the new value that was selected. In this case, the
 * selectedPermession variable is being updated with the new value that was selected.
 */
  onSelectedChange(event){
    this.selectedPermession = event
  }
}
