import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category, sub_category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-reply-contact',
  templateUrl: './reply-contact.component.html',
  styleUrls: ['./reply-contact.component.scss']
})
export class ReplyContactComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();

  message=""
  styles = [];

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    //this.open(this.model);
  }

/**
 * The function sends a reply message to a contact using an API endpoint.
 */
  reply() {
    if (this.model.id) {
      let body = {
        message: this.message,
      }
      this.add(`${environment.urls.contact.reply}${this.model.id}`, body , false).subscribe((res)=>{
        this.action.emit(res.status)
        this.activeModal.close()
      })
    }
  }
}
