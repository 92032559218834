import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-country-add-edit',
  templateUrl: './country-add-edit.component.html',
  styleUrls: ['./country-add-edit.component.scss']
})
export class CountryAddEditComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();
  types = [];

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    
  }
  
/**
 * The "save" function sends a request to update or insert a country using the model data and emits the
 * response status before closing the modal.
 */
  save() {
    var url = this.model.id ? `${environment.urls.country.update}${this.model.id}` : `${environment.urls.country.insert}`
    this.add(url, this.model, false).subscribe((res)=>{
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }
}
