import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { appConstant } from "src/app/service/appConstant";
import { environment } from "src/environments/environment";
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from "src/app/service/ConstantServiceWrapper.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
type UserFields = "email";
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})


export class ForgotComponent  extends BaseComponent implements OnInit {
  @ViewChild("Login") Login: ElementRef;

  public newUser = false;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    email: "",
  };
  public errorMessage: any;
  showLoader = false;

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    public toster: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
      this.loginForm = fb.group({email: ["", [Validators.required, Validators.email]]});
  }

  ngOnInit() {}

  // Simple Login
  save(form) {
    this.saveAdmin(form);
  }


/**
 * The function saves admin content and navigates to the login page if successful.
 * @param conent - It is a variable that contains data to be sent in the HTTP request body as JSON. The
 * specific content of the variable is not provided in the code snippet.
 */
  saveAdmin(conent) {
    this.showLoader = true;
    this.add(`${environment.urls.auth.forgot}`,  JSON.stringify(conent), false).subscribe(res_data=>{
      if(res_data.status) {
        setTimeout(() => {
          this.showLoader = false;
          this.router.navigate(['/auth/login'])
        }, 500); 
      }
    })
  }
}
