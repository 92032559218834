import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../base/base.component';
import { ConstantServiceWrapper } from '../../../service/ConstantServiceWrapper.service';
import { environment } from '../../../../environments/environment';
import { MouseEvent } from "@agm/core";
import { appConstant } from 'src/app/service/appConstant';
import { countries } from 'src/app/service/countries';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent extends BaseComponent implements OnInit {
  @Output() public action= new EventEmitter();

  model = {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    password: "",
    password_confirmation: "",
    code_country: "",
    slug_country: "",
    phoneModel: null
  }

  countries = countries.LIST
  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public route: ActivatedRoute,

  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }


  ngOnInit(): void {
   
  }

  saveAction() {   
    var url = "";
    if (this.model.id) {
        url = `${environment.urls.user.update}${this.model.id}`
    } else {
        url = `${environment.urls.user.addd}`
    }

    this.model.code_country = this.model.phoneModel.dialCode
    this.model.slug_country = this.model.phoneModel.code

    this.add(url, this.model, false).subscribe((res)=>{ 
      if(res.status){
        this.action.emit(res.status)
        this.activeModal.close()
      }
    })
  }
}