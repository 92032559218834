import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-about-edit',
  templateUrl: './about-edit.component.html',
  styleUrls: ['./about-edit.component.scss']
})

export class AboutEditComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();

  lang = ""
  formData = new FormData();
  types:any[] = Constant.ABOUT_TYPES

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    // this.lang = this.translate.currentLang
    // this.open(this.model);
  }
  
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.about.single}${model.id}`).subscribe((res)=>{
        if(res.status){
          let data = res.items as any;
          this.model = data as category;
        }
      })
    } else {
      this.model = new category()
    }
  }

/**
 * The function saves form data by appending it to a FormData object and then either edits or adds the
 * data to a specified URL.
 * @returns The function does not explicitly return anything, but it contains conditional statements
 * that may cause it to return early with a `return` statement.
 */
  saveAction() {
    this.formData = new FormData();
    if (!this.model.image || this.model.image == "") {
      this.formData = new FormData();
      this.toastr.error(this.translate.instant("VALIDATION.image"));
      return;
    }

    this.formData.append("image", this.model.image);
    this.formData.append("titleAR", this.model.titleAR);
    this.formData.append("titleEN", this.model.titleEN);
    this.formData.append("contentAR", this.model.contentAR);
    this.formData.append("contentEN", this.model.contentEN);
    this.formData.append("sort", this.model.sort);
    this.formData.append("type", this.model.type);
    this.formData.append("status", String(true));

    if (this.model.id) {
      this.edit(`${environment.urls.about.update}${this.model.id}`,this.formData, true).subscribe((res)=>{
        this.action.emit(res.status)
        this.activeModal.close();
      })
    } else {
      this.add(environment.urls.about.insert,this.formData, true).subscribe((res)=>{
        this.action.emit(res.status)
        this.activeModal.close();
      })
    }
  }

/**
 * This function processes an image file selected by the user and checks if it is a valid image file
 * type.
 * @param event - The event parameter is an object that represents the event that triggered the
 * function call. In this case, it is likely an event that is fired when a user selects an image file
 * using an input element in the HTML code.
 */
  processImage(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        const file: File = event.target.files[0]
        if(file.type.includes("image")){
          this.model.image = file as any
        }else{
          this.toastr.error(this.translate.instant('VALIDATION.invalid_image'))
        }
      };
    }else{
      this.model.image = ""
    }
  }

  onSelect(val){
    this.model.type = val
  }
}
