import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category, sub_category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-sub-category-add-edit',
  templateUrl: './sub-category-add-edit.component.html',
  styleUrls: ['./sub-category-add-edit.component.scss']
})

export class SubCategoryAddEditComponent extends BaseComponent implements OnInit {
  @Input() model: sub_category = new sub_category()
  @Input() parent_id: string = ""
  @Input() categories:category[] = []
  @Output() public action= new EventEmitter();
  isSupportAr = Constant.isSupportAr

  styles = [];

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    this.open(this.model);
  }

/**
 * The function opens a subcategory model by either retrieving it from the server or creating a new
 * one.
 * @param model - The `model` parameter is an object that represents a subcategory. It may have an `id`
 * property, which is used to retrieve an existing subcategory from the server. If the `id` property is
 * not present, a new subcategory object is created.
 */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.subcategory.single}${model.id}`).subscribe((res)=>{
        if(res.status){
          this.model = res.items as any;
          this.model.parent_id = this.model["parent"]["id"]
        }
      })
    } else {
      this.model = new sub_category()
    }
  }

/**
 * The function saves a subcategory by sending a POST or PUT request to the server and emits the status
 * of the action.
 */
  saveAction() {
    this.model.parent_id = this.parent_id
    var url = (this.model.id) ? `${environment.urls.subcategory.update}${this.model.id}` : environment.urls.subcategory.insert
    this.add(url, this.model, false).subscribe((res)=>{
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }

/**
 * The function sets the parent_id property of a model to a selected value.
 * @param val - val is a parameter that represents the value selected by the user in a dropdown or
 * select input field. It is being passed as an argument to the onSelect() function. The function then
 * sets the parent_id property of the model object to the selected value.
 */
  onSelect(val){
    this.model.parent_id = val
  }
}
