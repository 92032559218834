import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "constant",
    loadChildren: () =>
      import("../../components/constant/constant.module").then(
        (m) => m.ConstantModule
      ),
    data: {
      breadcrumb: "Constant",
    },
  },
  {
    path: "review",
    loadChildren: () =>
      import("../../components/reviews/review.module").then(
        (m) => m.ReviewModule
      ),
    data: {
      breadcrumb: "Review",
    },
  },
  {
    path: "coupon",
    loadChildren: () =>
      import("../../components/coupons/coupon.module").then(
        (m) => m.CouponsModule
      ),
    data: {
      breadcrumb: "Coupons",
    },
  },
  {
    path: "notification",
    loadChildren: () =>
      import("../../components/notification/notification.module").then(
        (m) => m.NotificationModule
      ),
    data: {
      breadcrumb: "Notification",
    },
  },

  {
    path: "guide",
    loadChildren: () =>
      import("../../components/guide/guide.module").then(
        (m) => m.GuideModule
      ),
    data: {
      breadcrumb: "GuideLandingPage",
    },
  },
  {
    path: "message",
    loadChildren: () =>
      import("../../components/messages/message.module").then(
        (m) => m.MessageModule
      ),
    data: {
      breadcrumb: "Messages",
    },
  },
  {
    path: "products",
    loadChildren: () =>
      import("../../components/product/product.module").then(
        (m) => m.ProductModule
      ),
    data: {
      breadcrumb: "Products",
    },
  },
  {
    path: "branch",
    loadChildren: () =>
      import("../../components/branch/branch.module").then(
        (m) => m.BracnhModule
      ),
    data: {
      breadcrumb: "Map",
    },
  },

  {
    path: "users",
    loadChildren: () =>
      import("../../components/actors/actors.module").then(
        (m) => m.ActorsModule
      ),
    data: {
      breadcrumb: "Actors",
    },
  },
  {
    path: "orders",
    loadChildren: () =>
      import("../../components/orders/orders.module").then(
        (m) => m.OrdersModule
      ),
    data: {
      breadcrumb: "Orders",
    },
  },
  {
    path: "withdraw",
    loadChildren: () =>
      import("../../components/withdraw/withdraw.module").then(
        (m) => m.withdrawModule
      ),
    data: {
      breadcrumb: "Withdraw",
    },
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    data: {
      breadcrumb: "Dashboard",
    },
  },

  {
    path: "report",
    loadChildren: () =>
      import("../../components/report/report.module").then(
        (m) => m.ReportModule
      ),
    data: {
      breadcrumb: "Reports",
    },
  },

];
