import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { feature, sub_feature } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-sub-feature-add-edit',
  templateUrl: './sub-feature-add-edit.component.html',
  styleUrls: ['./sub-feature-add-edit.component.scss']
})
export class SubFeatureAddEditComponent  extends BaseComponent implements OnInit {
  @Input() model:sub_feature = new sub_feature()
  @Input() features:feature[] = []
  @Output() public action= new EventEmitter();

  styles = [];

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    this.open(this.model);
  }

 /**
  * The function opens a model by either retrieving it from the server or creating a new instance if it
  * doesn't have an ID.
  * @param model - The `model` parameter is an object that represents a sub-feature. It may have an
  * `id` property, which is used to retrieve an existing sub-feature from the server. If the `id`
  * property is not present, a new sub-feature object is created.
  */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.feature.single}${model.id}`).subscribe((res)=>{
        if(res.status){
            let data = res.items as any;
            this.model = data as sub_feature;
        }
      })
    } else {
      this.model = new sub_feature()
    }
  }

/**
 * The function saves a model by either updating or inserting it based on its ID.
 */
  saveAction() {
    if (this.model.id) {
      this.edit(`${environment.urls.feature.update}${this.model.id}`,this.model, false).subscribe((res)=>{
        this.action.emit(res.status)
        this.activeModal.close()
      })
    } else {
      this.add(environment.urls.feature.insert,this.model, false).subscribe((res)=>{
        this.action.emit(res.status)
        this.activeModal.close()
      })
    }
  }

/**
 * The function sets the value and attribute ID of a model based on the selected value.
 * @param val - The parameter `val` is a variable that represents the value that is selected by the
 * user. It is used in the `onSelect` function to determine which action to take based on the selected
 * value.
 */
  onSelect(val){
    this.model.value = val == 2 ? "#000" : ""
    this.model.attributeId = val
  }

  SelectColor(val) {

  }
}
