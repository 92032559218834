import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'validate-input',
  templateUrl: './validate-input.component.html',
  styleUrls: ['./validate-input.component.scss']
})
export class ValidateInputComponent implements OnInit {
  @Input()  title: any;

  constructor() { }

  ngOnInit(): void {
  
  }

}
