import { actionUserModel } from "./generalModel";

export class BaseModel {
  id: any = 0;
  insertedAt:Date;
  updateAt:Date;
  deletedAt:Date;
  insertedBy:actionUserModel;
  updatedBy:actionUserModel;
  deletedBy:actionUserModel;
}

