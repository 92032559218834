import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../base/base.component';
import { ConstantServiceWrapper } from '../../../service/ConstantServiceWrapper.service';
import { continentSliders, category } from '../../../model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';


@Component({
  selector: 'app-continent-sliders-add-edit',
  templateUrl: './continent-sliders-add-edit.component.html',
  styleUrls: ['./continent-sliders-add-edit.component.scss']
})

export class ContinentSlidersAddEditComponent extends BaseComponent implements OnInit {
  @Input() model: continentSliders = new continentSliders()
  @Input() continent_id: string = ""
  @Output() public action = new EventEmitter();
  formData = new FormData();
  isSupportAr = Constant.isSupportAr


  styles = [];
  imgUpload = false
  backgroundUpload = false
  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper, toastr, translate, activeModal, modalService)
  }

  ngOnInit(): void {
    this.open(this.model);
  }

  /**
   * The function opens a subcategory model by either retrieving it from the server or creating a new
   * one.
   * @param model - The `model` parameter is an object that represents a subcategory. It may have an `id`
   * property, which is used to retrieve an existing subcategory from the server. If the `id` property is
   * not present, a new subcategory object is created.
   */
  open(model) {
    if (model.id) {

      this.get(`${environment.urls.continentSilders.single}${model.id}`).subscribe((res) => {
        if (res.status) {
          this.model = res.items as any;
        }
      })

    } else {
      this.model = new continentSliders()
    }
  }

  /**
 * The function saves form data and uploads an image if it exists, otherwise it displays an error
 * message.
 * @returns The function does not have a return statement, but it may return undefined if the
 * conditions in the if statements are met.
 */
  saveAction() {
    if (!this.model.image || this.model.image == "") {
      // this.toastr.error(this.translate.instant("VALIDATION.image"));
      return;
    }

    if (!this.model.background || this.model.background == "") {
      this.toastr.error(this.translate.instant("VALIDATION.background"));
      return;
    }

    this.addEditSlidar();
  }

  uploadImage() {
    if (this.imgUpload) {
      let formData2 = new FormData();
      formData2.append("image", this.model.image);
      this.add(environment.urls.upload, formData2, true, false).subscribe((res) => {
        if (res.status) {
          let item = res.items as any;
          this.formData.append("image", item.image_name);
        }
      })
    }
  }

  uploadBg() {
    if (this.backgroundUpload) {
      let formData2 = new FormData();
      formData2.append("image", this.model.background);
      this.add(environment.urls.upload, formData2, true, false).subscribe((res) => {
        if (res.status) {
          let item = res.items as any;
          this.formData.append("background", item.image_name);
        }
      })
    }
    // return;

  }


  /**
   * The function adds or edits a category by appending form data and making a POST request to the
   * appropriate URL.
   */
  addEditSlidar() {
    if (this.isSupportAr) {
      this.formData.append("title_ar", this.model.title_ar);
    }
    this.formData.append("title_en", this.model.title_en);
    if (this.isSupportAr) {
      this.formData.append("text_ar", this.model.text_ar);
    }
    this.formData.append("text_en", this.model.text_en);

    var url = this.model.id ? `${environment.urls.continentSilders.update}${this.model.id}` : `${environment.urls.continentSilders.insert}/${this.continent_id}`
    this.add(url, this.formData, true).subscribe((res) => {
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }


  /**
   * The function sets the continent_id property of a model to a selected value.
   * @param val - val is a parameter that represents the value selected by the user in a dropdown or
   * select input field. It is being passed as an argument to the onSelect() function. The function then
   * sets the continent_id property of the model object to the selected value.
   */
  onSelect(val) {
    this.model.continent_id = val
  }

  /**
 * This function processes an image file selected by the user and checks if it is a valid image file
 * type.
 * @param event - The event parameter is an object that represents the event that triggered the
 * function call. In this case, it is the event of a file being selected by the user through an input
 * element.
 */
  processImage(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        const file: File = event.target.files[0]
        if (file.type.includes("image")) {
          this.model.image = file as any
          this.imgUpload = true
          this.uploadImage();
        } else {
          this.toastr.error(this.translate.instant('VALIDATION.invalid_image'))
        }
      };
    } else {
      this.model.image = ""
      this.imgUpload = false
    }
  }

  processBackground(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        const file: File = event.target.files[0]
        if (file.type.includes("image")) {
          this.model.background = file as any
          this.backgroundUpload = true
          this.uploadBg();
        } else {
          this.toastr.error(this.translate.instant('VALIDATION.invalid_image'))
        }
      };
    } else {
      this.model.background = ""
      this.backgroundUpload = false
    }
  }


}
