import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-faq-add-edit',
  templateUrl: './faq-add-edit.component.html',
  styleUrls: ['./faq-add-edit.component.scss']
})
export class FaqAddEditComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();
  types = [];
  lang = ""
  isSupportAr = Constant.isSupportAr

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang
    this.open(this.model);
  }
  
/**
 * The function opens a model by either retrieving it from the server or creating a new one if it
 * doesn't have an ID.
 * @param model - The `model` parameter is an object that represents a category. It has an `id`
 * property that is used to identify the category. If the `id` property is present, the function
 * retrieves the category from the server using an HTTP GET request. If the `id` property is not
 * present
 */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.faq.single}${model.id}`).subscribe((res)=>{
        if(res.status){
          let data = res.items as any;
          this.model = data as any;
        }
      })
    } else {
      this.model = new category()
    }
  }

/**
 * The function saves a model by updating or inserting it into a database and emits an action event
 * before closing a modal.
 */
  savemodel() {
    this.model.status = true;
    this.model.type = this.model.type_e;
    let url = this.model.id ? `${environment.urls.faq.update}${this.model.id}` : `${environment.urls.faq.insert}`
    
    this.add(url, this.model, false).subscribe((res)=>{
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }
}
