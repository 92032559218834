import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';


@Component({
  selector: 'app-categroy-add-edit',
  templateUrl: './categroy-add-edit.component.html',
  styleUrls: ['./categroy-add-edit.component.scss']
})
export class CategroyAddEditComponent extends BaseComponent implements OnInit {
  @Input() model: category = new category()
  @Output() public action = new EventEmitter();
  formData = new FormData();
  actions = Constant.cms_actions
  isSupportAr = Constant.isSupportAr

  styles = [];
  imgUpload = false
  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper, toastr, translate, activeModal, modalService)
  }

  ngOnInit(): void {
    this.open(this.model);
  }

  /**
   * The function opens a category model by either retrieving it from the server or creating a new one.
   * @param model - The `model` parameter is an object that represents a category. It has an `id`
   * property that is used to identify the category. The `open` method is used to retrieve the category
   * data from the server if the `id` property is present in the `model` object. If the
   */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.category.single}${model.id}`).subscribe((res) => {
        if (res.status) {
          let data = res.items as any;
          this.model = data as category;
        }
      })
    } else {
      this.model = new category()
    }
  }

  /**
   * The function saves form data and uploads an image if it exists, otherwise it displays an error
   * message.
   * @returns The function does not have a return statement, but it may return undefined if the
   * conditions in the if statements are met.
   */
  saveAction() {
    this.formData = new FormData();
    if (!this.model.image || this.model.image == "") {
      this.formData = new FormData();
      this.toastr.error(this.translate.instant("VALIDATION.image"));
      return;
    }

    if (this.imgUpload) {
      let formData2 = new FormData();
      formData2.append("image", this.model.image);
      this.add(environment.urls.upload, formData2, true, false).subscribe((res) => {
        if (res.status) {
          let item = res.items as any;
          this.formData.append("image", item.image_name);
          this.addEditCategory();
        }
      })
    } else {
      this.addEditCategory();
    }
  }


  /**
   * The function adds or edits a category by appending form data and making a POST request to the
   * appropriate URL.
   */
  addEditCategory() {
    if (this.isSupportAr) {
      this.formData.append("name_ar", this.model.name_ar);
    }
    this.formData.append("name_en", this.model.name_en);
    // this.formData.append("value_2", String(this.model.value_2));
    this.formData.append("is_active", String(this.model.is_active ? 1 : 0));


    var url = this.model.id ? `${environment.urls.category.update}${this.model.id}` : `${environment.urls.category.insert}`
    this.add(url, this.formData, true).subscribe((res) => {
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }

  /**
   * This function processes an image file selected by the user and checks if it is a valid image file
   * type.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function call. In this case, it is the event of a file being selected by the user through an input
   * element.
   */
  processImage(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        const file: File = event.target.files[0]
        if (file.type.includes("image")) {
          this.model.image = file as any
          this.imgUpload = true
        } else {
          this.toastr.error(this.translate.instant('VALIDATION.invalid_image'))
        }
      };
    } else {
      this.model.image = ""
      this.imgUpload = false
    }
  }

}
