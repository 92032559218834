import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss']
})
export class TableActionsComponent implements OnInit {
  isOpenFilter = false
  @Input() id;
  @Input() showView = true;
  @Input() hideEdit = false;
  @Input() hideDelete = false;
  @Output() deleteAction = new EventEmitter<any>();
  @Output() editAction = new EventEmitter<any>();
  @Output() toggleAction = new EventEmitter<any>();
  
  constructor() { 
    
  }

  ngOnInit(): void {
    
  }

  delete(){
    this.deleteAction.emit(this.id);
  }

  edit(){
    this.editAction.emit(this.id);
  }
  
}
