import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PagingResponse } from 'src/app/model/generalModel';
import { brand, Order } from 'src/app/model/model';
import { Constant } from 'src/app/service/appConstant';
import { ConstantServiceWrapper } from 'src/app/service/ConstantServiceWrapper.service';
import { environment } from 'src/environments/environment';
import { BaseComponent, MomentDateFormatter } from '../../base/base.component';
@Component({
  selector: 'app-financial-statistics',
  templateUrl: './financial-statistics.component.html',
  styleUrls: ['./financial-statistics.component.scss']
})
export class FinancialStatisticsComponent extends BaseComponent implements OnInit {
  stores = []
  branch = []
  products = []
  category = []
  lists = []


  statistics = {
    gross_revenue: 10,
    net_revenue: 2,
    net_profit: 5,
    gross_margin: 4,
    net_profit_margin: 3,
    cash_flow: 6,
    cac: 4,
    gross_merchandise_volume: 43,
    seven_j_fees: 5,
    churn_rate: 4,
    aov: 5,
    refund_total: 9,
    operation_cost: 6,
  }

  searchObject = {
    dt_from: "",
    dt_to: "",
    vendor_id: "",
    category_id: "",
    branch_id: "",
    product_id: ""
  };

  constructor(
    public helper: ConstantServiceWrapper,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
  ) {
    super(helper, toastr, translate, activeModal, modalService)
    //this.lang = this.translate.currentLang
  }

  ngOnInit(): void {
    // this.getStores()
    // this.getBranch()
    // this.getProduct()
    // this.getCategory()
    // this.getData(this.page)
  }

  /**
   * This function retrieves a list of stores from a specified URL and assigns them to a variable.
   */
  getStores() {
    this.get(`${environment.urls.vendor.list}?is_paginate=0`).subscribe(res => {
      let items = res.items as any
      let response = items as PagingResponse<any>
      this.stores = response.items
    });
  }

  /**
   * The function retrieves a list of branches from a specified URL and assigns them to a variable.
   */
  getBranch() {
    this.get(`${environment.urls.branch.list}?is_paginate=0`).subscribe(res => {
      let items = res.items as any
      let response = items as PagingResponse<any>
      this.branch = response.items
    });
  }

  /**
   * The function retrieves a list of products from a specified URL and assigns them to a variable.
   */
  getProduct() {
    this.get(`${environment.urls.product.search}?is_paginate=0`).subscribe(res => {
      let items = res.items as any
      let response = items as PagingResponse<any>
      this.products = response.items
    });
  }

  getCategory() {
    this.get(`${environment.urls.category.list}?is_paginate=0`).subscribe(res => {
      let items = res.items as any
      let response = items as PagingResponse<any>
      this.category = response.items
    });
  }

  /**
   * The function exports financial data to an Excel file based on search criteria.
   */
  excel() {
    var from = ""
    var to = ""
    if (this.searchObject.dt_from != "") {
      let dt_from = this.momentFormat.format(this.searchObject.dt_from as any);
      from = dt_from;
    }
    if (this.searchObject.dt_to != "") {
      let dt_to = this.momentFormat.format(this.searchObject.dt_to as any);
      to = dt_to;
    }
    if (this.searchObject.category_id == null) {
      this.searchObject.category_id = ""
    }
    if (this.searchObject.vendor_id == null) {
      this.searchObject.vendor_id = ""
    }
    if (this.searchObject.product_id == null) {
      this.searchObject.product_id = ""
    }
    if (this.searchObject.branch_id == null) {
      this.searchObject.branch_id = ""
    }
    this.get(`${environment.urls.report.financial_excel}?from_date=${from}&to_date=${to}&branch_id=${this.searchObject.branch_id}&vendor_id=${this.searchObject.vendor_id}&category_id=${this.searchObject.category_id}`).subscribe(res => {
      this.toastr.clear()
      let items = res.items as any
      let url = items.file_url
      var element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("target", "_blank");
      element.setAttribute("download", `sales_report_${from}_${to}` + ".xlsx");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    })
  }

  /**
   * The function retrieves financial data based on search criteria and pagination.
   * @param {number} page - The page number of the data to be retrieved. This is used for pagination
   * purposes.
   */
  getData(page: number) {
    var from = ""
    var to = ""
    if (this.searchObject.dt_from != "") {
      let dt_from = this.momentFormat.format(this.searchObject.dt_from as any);
      from = dt_from;
    }
    if (this.searchObject.dt_to != "") {
      let dt_to = this.momentFormat.format(this.searchObject.dt_to as any);
      to = dt_to;
    }
    if (this.searchObject.category_id == null) {
      this.searchObject.category_id = ""
    }
    if (this.searchObject.vendor_id == null) {
      this.searchObject.vendor_id = ""
    }
    if (this.searchObject.product_id == null) {
      this.searchObject.product_id = ""
    }
    if (this.searchObject.branch_id == null) {
      this.searchObject.branch_id = ""
    }
    this.get(`${environment.urls.report.financial_list}?page_number=${page}&from_date=${from}&to_date=${to}&branch_id=${this.searchObject.branch_id}&vendor_id=${this.searchObject.vendor_id}&category_id=${this.searchObject.category_id}`).subscribe(res => {
      this.toastr.clear()
      let items = res.items as any
      let response = items as PagingResponse<any>
      this.collectionCount = response.total_records
      this.totalPages = response.total_pages
      this.lists = response.items as []
    })
  }

  /**
   * The function updates the current page number and retrieves data if the page number is within the
   * total number of pages.
   * @param {number} pageNum - a number representing the page number that the user has selected.
   */
  onPageChange(pageNum: number): void {
    this.page = pageNum;
    if (this.page <= this.totalPages) {
      this.getData(this.page)
    }
  }

  /**
   * The function resets search parameters and retrieves data for the first page.
   */
  reset() {
    this.searchObject = {
      dt_from: "",
      dt_to: "",
      vendor_id: "",
      category_id: "",
      branch_id: "",
      product_id: ""
    }
    this.page = 1
    this.getData(this.page)
  }

  search() {
    this.page = 1
    this.getData(this.page)
  }

}
