import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';
import { PagingResponse } from 'src/app/model/generalModel';


@Component({
  selector: 'app-notification-add',
  templateUrl: './notification-add.component.html',
  styleUrls: ['./notification-add.component.scss']
})
export class NotificationAddComponent extends BaseComponent implements OnInit {
  @Output() public action= new EventEmitter();
  model = {
    title: "",
    text: "",
    type: "",
    name: "",
    ids:[],
    newtype: Constant.NotificationCategory.all
  }
  types = Constant.UserNotificationTypes;
  lang;
  users = []
  countries = []
  isSelectRow = [];
  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
    this.lang = translate.currentLang
  }

  ngOnInit(): void {

  }
  
/**
 * The function clears the name property of a model, sets its type to a new type, adds it to a
 * notification URL, and emits an action before closing a modal.
 */
  savemodel() {
    this.model.name = "";
    this.model.type = this.model.newtype;
    this.add(`${environment.urls.notification.add}`, this.model, false).subscribe((res)=>{
      this.action.emit(res.status)
      this.activeModal.close()
    })
  }


  getAllUsers(name: String) {
    this.get(`${environment.urls.user.search}?name=${name}`).subscribe((res)=>{
      if(res.status){
        this.users = res.items as any[]
      }
    })
  }

  getAllVendors(name: String) {
    this.get(`${environment.urls.vendor.list}?page_number=1&name=${name}&branch_id=`).subscribe(res=>{
      let items = res.items as any
      let response = items as PagingResponse<any>
      this.collectionCount = response.total_records
      this.totalPages = response.total_pages
      this.users = response.items as any[]
    })
  }

  onChangeUsers(event) {
    let val = event.target.value
    this.getAllUsers(val);
  }

  onChangeVendors(event){
    let val = event.target.value
    this.getAllVendors(val);
  }
 
  selectUser(id){
    if(this.model.ids.includes(id)){
      let index = this.model.ids.indexOf(id);
      this.model.ids.splice(index, 1);
    }else{
      this.model.ids.push(id)
    }
  }

  selectVendor(id){
    if(this.model.ids.includes(id)){
      let index = this.model.ids.indexOf(id);
      this.model.ids.splice(index, 1);
    }else{
      this.model.ids.push(id)
    }
  }

  onChangeType(event){
    this.model.ids = []
    this.users = []
    this.model.name
  }
}
