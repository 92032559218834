import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { UserType } from "src/app/service/appConstant";
import { NavService, Menu } from "../../services/nav.service";
import { BaseComponent } from "src/app/components/base/base.component";
import { ConstantServiceWrapper } from "src/app/service/ConstantServiceWrapper.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent extends BaseComponent {
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  public name = "";
  public user_id = "";
  public type = "";
  public userImage = null;
  public get UserType(): typeof UserType {
    return UserType;
  }
  constructor(
    public helper: ConstantServiceWrapper,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
    public router: Router,
    public navServices: NavService
  ) {
    super(helper, toastr, translate, activeModal, modalService )
    if (localStorage.getItem("token") != null) {
      this.get(`${environment.urls.admin.profile}`).subscribe((res)=>{
        if(res.status){
          let obj = res.items as any
          this.name = obj['user']["name"] as any
          // this.model_id = model_id
        }
      })
      // this.name = localStorage.getItem("admin_username");
      // this.user_id = localStorage.getItem("admin_id");
      // this.type = localStorage.getItem("type");
      // this.userImage = localStorage.getItem("image");
    }
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter((items) => {
            if (items.path === event.url) this.setNavActive(items);
            if (!items.children) return false;
            items.children.filter((subItems) => {
              if (subItems.path === event.url) this.setNavActive(subItems);
              if (!subItems.children) return false;
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems);
              });
            });
          });
        }
      });
    });
  }

  // constructor(private router: Router, public navServices: NavService) {
   
  // }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }
}
