import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { appConstant } from "src/app/service/appConstant";
import { environment } from "src/environments/environment";
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from "src/app/service/ConstantServiceWrapper.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

type UserFields = "name" | "email" | "password";
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent extends BaseComponent implements OnInit {
  @ViewChild("Login") Login: ElementRef;

  public newUser = false;
  public registerForm: FormGroup;
  public formErrors: FormErrors = {
    email: "",
    password: "",
    name: ""
  };
  public errorMessage: any;
  showLoader = false;

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    public toster: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
      this.registerForm = fb.group({
        name: ["", [Validators.required]],
        email:    ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit() {

  }

  register(form) {
    this.registerAdmin(form);
  }

/**
 * The function registers an admin by sending a POST request to the server and navigating to the login
 * page upon successful registration.
 * @param conent - It is likely a variable that contains the data to be sent in the request body as
 * JSON. The exact structure of the data is not clear from the provided code snippet.
 */
  registerAdmin(conent) {
    this.showLoader = true;
    this.add(`${environment.urls.auth.signup}`, JSON.stringify(conent), false).subscribe(res_data=>{
      if(res_data.status){
        setTimeout(() => {
          this.showLoader = false;
          this.router.navigate(['/auth/login']);
          //this.Login.nativeElement.click();
        }, 1000);   
      }
    })
  }
}
