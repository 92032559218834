import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-governorate-add-edit',
  templateUrl: './governorate-add-edit.component.html',
  styleUrls: ['./governorate-add-edit.component.scss']
})
export class GovernorateAddEditComponent extends BaseComponent implements OnInit {
  @Input() model:any;
  @Output() public action= new EventEmitter();
  countries = [];

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }


  ngOnInit(): void {
    // this.getCountryData()
  }

  getCountryData() {
    this.get(`${environment.urls.country.all}`).subscribe((res)=>{
      if(res.status)
        this.countries = res.items as any[]
    })
  }

  onSelect(val){
    this.model.countryId = val
  }

  save() {
    if(this.model.id){
      this.edit(`${environment.urls.governorate.update}${this.model.id}`, this.model, false).subscribe((res)=>{
        this.action.emit(res.status)
        this.activeModal.close()
      })
    }else{
      this.add(`${environment.urls.governorate.add}`, this.model, false).subscribe((res)=>{
        this.action.emit(res.status)
        this.activeModal.close()
      })
    }
   
  }
}


