import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { ToastrModule } from "ngx-toastr";
import { ColorPickerModule } from "@syncfusion/ej2-angular-inputs";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CKEditorModule } from "ngx-ckeditor";

import { AdminGuard } from "./shared/guard/admin.guard";
import { SecureInnerPagesGuard } from "./shared/guard/SecureInnerPagesGuard.guard";
import { CookieService } from "ngx-cookie-service";
import { NgSelectModule } from "@ng-select/ng-select";
import { BaseComponent } from './components/base/base.component';
import { CategroyAddEditComponent } from './components/modals/categroy-add-edit/categroy-add-edit.component';
import { ContinentAddEditComponent } from './components/modals/continent-add-edit/continent-add-edit.component';
import { SubFeatureAddEditComponent } from './components/modals/sub-feature-add-edit/sub-feature-add-edit.component';
import { OrderDetailsComponent } from './components/modals/order-details/order-details.component';
import { StaticPageEditComponent } from './components/modals/static-page-edit/static-page-edit.component';
import { SubCategoryAddEditComponent } from './components/modals/sub-category-add-edit/sub-category-add-edit.component';
import { ContinentSlidersAddEditComponent } from './components/modals/continent-sliders-add-edit/continent-sliders-add-edit.component';
import { ReplyContactComponent } from './components/modals/reply-contact/reply-contact.component';
import { AboutEditComponent } from './components/modals/about-edit/about-edit.component';
import { FaqAddEditComponent } from './components/modals/faq-add-edit/faq-add-edit.component';
import { NotificationAddComponent } from './components/modals/notification-add/notification-add.component';
import { CountryAddEditComponent } from './components/modals/country-add-edit/country-add-edit.component';
import { GovernorateAddEditComponent } from './components/modals/governorate-add-edit/governorate-add-edit.component';
import { AdvsAddEditComponent } from './components/modals/advs-add-edit/advs-add-edit.component';
import { branchAddEditComponent } from "./components/modals/bracnh-add-edit/branch-add-edit.component";
import { AgmCoreModule } from "@agm/core";
import { environment } from "src/environments/environment";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BankAddEditComponent } from './components/modals/bank-add-edit/bank-add-edit.component';
import { ReasonOrderComponent } from './components/modals/reason-order/reason-order.component';
import { RegisterComponent } from './auth/register/register.component';
import { RequestChangeComponent } from './components/modals/request-change/request-change.component';
import { SalesComponent } from './components/report/sales/sales.component';
import { FinanceComponent } from './components/report/finance/finance.component';
import { EarningComponent } from './components/report/earning/earning.component';
import { FinancialStatisticsComponent } from './components/report/financial-statistics/financial-statistics.component';
import { UserAddComponent } from './components/modals/user-add/user-add.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { PasswordComponent } from './components/modals/password/password.component';
import { ClientViewComponent } from './components/modals/client-view/client-view.component';
import { RoleAddEditComponent } from './components/modals/role-add-edit/role-add-edit.component';
import { TreeviewModule } from 'ngx-treeview';
import { ErrorPageComponent } from './auth/error-page/error-page.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, LoginComponent, BaseComponent, CategroyAddEditComponent, ContinentAddEditComponent, SubFeatureAddEditComponent, OrderDetailsComponent, StaticPageEditComponent, ReplyContactComponent, AboutEditComponent, FaqAddEditComponent, NotificationAddComponent, CountryAddEditComponent, branchAddEditComponent, GovernorateAddEditComponent, AdvsAddEditComponent,
    SubCategoryAddEditComponent,
    ContinentSlidersAddEditComponent,
    BankAddEditComponent, ReasonOrderComponent, RegisterComponent, RequestChangeComponent, SalesComponent, FinanceComponent, FinancialStatisticsComponent, EarningComponent, UserAddComponent, ForgotComponent, PasswordComponent, ClientViewComponent, RoleAddEditComponent, ErrorPageComponent, ChangePasswordComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    CKEditorModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }
    ),
    NgbModule,
    NgSelectModule,
    ColorPickerModule,
    AngularMultiSelectModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPI,
      libraries: ["places", "drawing", "geometry"],
    }),
    TreeviewModule.forRoot()
  ],
  providers: [AdminGuard, SecureInnerPagesGuard, CookieService],
  bootstrap: [AppComponent],
})
export class AppModule { }
