import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AdminRoles } from "src/app/service/appConstant";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(public router: Router) {}


  getMyRoles() {
    if (localStorage.getItem("roles")) {
      let decryptToken = CryptoJS.AES.decrypt(localStorage.getItem("roles"),  environment.key);
      var decryptedData = decryptToken.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    }else{
      return ""
    }
  }
    
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var roles = []

    // if(this.isPageRefresh()){
      return true
    // }

    if (localStorage.getItem("token") != null) { 
      roles = this.getMyRoles();
      if(state.url.includes('dashboard') || state.url.includes('profile')){
        return true
      }else{
        let role = next.data.roles as string;
        let findURL = roles.find(x=>x.permession.includes(String(role)));
        if(findURL){
          return true
        }else{      
          let findURL = roles.find(x=>x.url.includes(String(state.url.replace(/[0-9]/g, ''))));
          if(findURL){
            return true
          }else{
            this.router.navigate(["/error"]);
            return false;
          }
        }
      }
    } else {
      this.router.navigate(["/auth/login"]);
      return false;
    }
  }

  private isPageRefresh() : boolean {
		return(this.router.navigated)
	}
}
