import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../../app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category, sub_category } from '../../../../app/model/model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';

@Component({
  selector: 'app-request-change',
  templateUrl: './request-change.component.html',
  styleUrls: ['./request-change.component.scss']
})
export class RequestChangeComponent extends BaseComponent implements OnInit {
  @Input() name:any;
  @Input() userId:any;
  @Input() objectId:any;
  @Input() type:any = 'orders';
  @Output() public action= new EventEmitter();
  StatusList = Constant.StatusList;
  StatusArrayList = Constant.StatusArrayList
  
  model = {
    message: "",
    status: ""
  }

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
  }


  ngOnInit(): void {
  }


/**
 * The function "change" updates the status and reason for refusal of an order or a withdrawal request.
 */
  change() {
    if(this.type == 'order'){
      let body = { status: this.StatusArrayList.unacceptable, reason_refuse: this.model.message }
      this.add(`${environment.urls.request.update}${this.objectId}`, body, false).subscribe(res=>{
        if(res.status){
          this.action.emit(res.status)
          this.activeModal.close()
        }
      })
    }else{
      let body = { status: Constant.WithdrawStatusArrayList.unacceptable, reason_refuse: this.model.message }
      this.add(`${environment.urls.withdraw.update}${this.objectId}`, body, false).subscribe(res=>{
        if(res.status){
          this.action.emit(res.status)
          this.activeModal.close()
        }
      })
    }
  }
}
