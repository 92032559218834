import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { generalModel } from 'src/app/model/generalModel';

@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss']
})
export class DropDownListComponent  implements OnInit {
  @Input() isRequired:boolean;
  @Input() disabled:boolean;
  @Input() value;
  @Input() list: generalModel[];
  @Output() valueChanged = new EventEmitter<any>();

  constructor(private traslate: TranslateService) { }

  ngOnInit(): void {

  }

  onSelect(event){
    this.valueChanged.emit(event);
  }

  // controlHasError(formGroup: FormGroup, validation, controlName): boolean {
  //   const control = formGroup.controls[controlName];
  //   return control.hasError(validation) && (control.dirty || control.touched);
  // } 
}
