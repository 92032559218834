import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { category } from '../../../../app/model/model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Constant } from 'src/app/service/appConstant';


@Component({
  selector: 'app-advs-add-edit',
  templateUrl: './advs-add-edit.component.html',
  styleUrls: ['./advs-add-edit.component.scss']
})
export class AdvsAddEditComponent extends BaseComponent implements OnInit {
  @Input() model: any
  @Input() type: String = Constant.ADVSTYPE.banner
  @Output() public action = new EventEmitter();

  isSupportAr = Constant.isSupportAr
  formData = new FormData();
  types = [];
  actions = [];
  categories = [];
  subcategories = [];
  stores = [];
  lang;
  imgUpload = false;

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super(helper, toastr, translate, activeModal, modalService)
    this.types = Constant.AdbsType
    this.actions = Constant.AdbsActionType
    this.lang = translate.currentLang
  }


  ngOnInit(): void {
    this.open(this.model);
  }


  /**
   * The "open" function retrieves data from the server based on a given ID, or initializes a new object
   * if no ID is provided.
   * @param model - The `model` parameter is an object that represents an advertisement. It may have an
   * `id` property, which is used to retrieve an existing advertisement from the server. If the `id`
   * property is not present, a new advertisement object is created with default values for its
   * properties.
   */
  open(model) {
    if (model.id) {
      this.get(`${environment.urls.advs.single}${model.id}`).subscribe((res) => {
        if (res.status) {
          this.model = res.items as any;
        }
      })
    }
    else {
      this.model = {
        title_ar: "",
        title_en: "",
        image: "",
        text_ar: "",
        text_en: "",
        btn_title_ar: "",
        btn_title_en: "",
        btn_link: ""
      }
    }
  }


  /**
   * The function saves data by checking if an image is present and uploading it if necessary.
   * @returns The function does not return anything explicitly, but it may return control to the calling
   * function if the image is missing or invalid.
   */
  saveAction() {
    this.formData = new FormData();
    if (!this.model.image || this.model.image == "") {
      this.formData = new FormData();
      this.toastr.error(this.translate.instant("VALIDATION.image"));
      return;
    }

    if (this.imgUpload) {
      let formData2 = new FormData();
      formData2.append("image", this.model.image);
      this.add(environment.urls.upload, formData2, true, false).subscribe((res) => {
        if (res.status) {
          let item = res.items as any;
          this.formData.append("image", item.image_name);
          this.addEditSlider();
        }
      })
    } else {
      this.addEditSlider();
    }
  }

  /**
   * The function adds and edits a slider by appending form data and making a request to update the
   * slider with the provided ID.
   */

  addEditSlider() {
    if (this.isSupportAr) {
      this.formData.append("title_ar", this.model.title_ar);
    }

    this.formData.append("title_en", this.model.title_en);

    if (this.isSupportAr) {
      this.formData.append("text_ar", this.model.text_ar);
    }

    this.formData.append("text_en", this.model.text_en);

    if (this.isSupportAr) {
      this.formData.append("btn_title_ar", this.model.btn_title_ar);
    }

    this.formData.append("btn_title_en", this.model.btn_title_en);
    this.formData.append("btn_link", this.model.btn_link);

    this.add(environment.urls.advs.update + this.model.id, this.formData, true).subscribe((res) => {
      this.action.emit(res.status)
      this.activeModal.close()
    });
  }


  /**
   * This function processes an image file selected by the user and checks if it is a valid image file
   * type.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function call. In this case, it is likely an event that is fired when a user selects an image file
   * to upload.
   */
  processImage(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        const file: File = event.target.files[0]
        if (file.type.includes("image")) {
          this.model.image = file as any
          this.imgUpload = true
        } else {
          this.toastr.error(this.translate.instant('VALIDATION.invalid_image'))
        }
      };
    } else {
      this.model.image = ""
      this.imgUpload = false
    }
  }
}
