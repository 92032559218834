import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
import { AdminRoles, appConstant } from "src/app/service/appConstant";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  orderNum?: number;
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen = false;
  public MENUITEMS: Menu[] = []
  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
    //this.getMyRoles();
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  getMyRoles() {
    if (localStorage.getItem("roles")) {
      let decryptToken = CryptoJS.AES.decrypt(localStorage.getItem("roles"), environment.key);
      var decryptedData = decryptToken.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } else {
      return ""
    }
  }

  getMenu() {

    var userRoles: any[] = this.getMyRoles();

    this.MENUITEMS = [];

    let home = {
      path: "/dashboard/default",
      title: "Dashboard",
      icon: "home",
      type: "link",
      active: true,
      orderNum: 1,
    }

    let Orders = {
      title: "Orders",
      icon: "shopping-bag",
      type: "link",
      path: "/orders/list",
      active: false,
      orderNum: 2,
    };

    let Shops = {
      title: "Shops",
      icon: "shopping-cart",
      type: "sub",
      active: false,
      children: [],
      orderNum: 3,
    };

    let Products = {
      title: "Products",
      icon: "list",
      type: "sub",
      active: false,
      children: [],
      orderNum: 4,
    };

    let finance_statistics = {
      path: "/report/financial-statistics",
      title: "financeStatistics",
      icon: "dollar-sign",
      type: "link",
      active: false,
      orderNum: 5,
    };

    let Clients = {
      path: "/users/clients",
      title: "Clients",
      icon: "users",
      type: "link",
      active: false,
      orderNum: 6,
    };

    let Branches = {
      title: "Branches",
      icon: "package",
      type: "link",
      active: false,
      path: "/constant/branch",
      orderNum: 7,
    };

    let Coupons = {
      title: "Coupons",
      icon: "folder-minus",
      type: "link",
      active: false,
      path: "/coupon/list",
      orderNum: 8,
    };

    let Withdraw = {
      title: "Withdraw",
      icon: "layers",
      type: "link",
      path: "/withdraw/list",
      active: false,
      orderNum: 9,
    }

    let Notification = {
      title: "Notification",
      icon: "message-square",
      type: "link",
      path: "/notification/all",
      active: false,
      orderNum: 10,
    }


    let constant = {
      title: "Constant",
      icon: "slack",
      type: "sub",
      active: false,
      children: [],
      orderNum: 11,
    };

    let settings = {
      title: "Settings",
      icon: "settings",
      type: "link",
      path: "/constant/settings",
      active: false,
      orderNum: 12,
    };




    let Admins = {
      title: "Admins",
      icon: "user",
      type: "sub",
      active: false,
      children: [],
      orderNum: 13,
    };


    // let Requests = {
    //   title: "Requests",
    //   icon: "plus",
    //   type: "sub",
    //   active: false,
    //   children: [],
    //   orderNum: 13,
    // };


    let Review = {
      title: "customerReview",
      icon: "star",
      type: "sub",
      active: false,
      children: [],
      orderNum: 14,
    }

    let Reports = {
      title: "Reports",
      icon: "file-text",
      type: "sub",
      active: false,
      children: [],
      orderNum: 15,
    }



    let GuideLandingPage = {
      title: "GuideLandingPage",
      icon: "info",
      type: "sub",
      active: false,
      children: [],
      orderNum: 16,
    }

    this.MENUITEMS.push(home);

    // this.MENUITEMS.push(Orders);

    //children
    userRoles.forEach((el) => {
      var element = el.permession
      if (element == AdminRoles.show_general_settings) {
       

        if (!this.MENUITEMS.find(x => x.path == settings.path)) {
          this.MENUITEMS.push(settings);
        }

        // if (!constant.children.find(x => x.path == obj.path)) {
        //   constant.children.push(obj)
        // }
      }
      if (element == AdminRoles.show_categories) {
        let obj = { path: "/constant/category", title: "Category", type: "link" };
        if (!constant.children.find(x => x.path == obj.path)) {
          constant.children.push(obj)
        }

        let obj_2 = { path: "/constant/continents", title: "Continents", type: "link" };
        if (!constant.children.find(x => x.path == obj_2.path)) {
          constant.children.push(obj_2)
        }


      }
      if (element == AdminRoles.show_banks) {
        let obj = { path: "/constant/bank", title: "Banks", type: "link" };
        if (!constant.children.find(x => x.path == obj.path)) {
          constant.children.push(obj)
        }
      }
      if (element == AdminRoles.show_banners) {
        let obj = { path: "/constant/slider", title: "Sliders", type: "link" };
        if (!constant.children.find(x => x.path == obj.path)) {
          constant.children.push(obj)
        }
      }
      if (element == AdminRoles.show_social_links) {
        let obj = { path: "/constant/social", title: "SocialLink", type: "link" };
        if (!constant.children.find(x => x.path == obj.path)) {
          constant.children.push(obj)
        }
      }
      if (element == AdminRoles.show_static_pages) {
        let obj = { path: "/constant/staticpage", title: "Staticpage", type: "link" };
        if (!constant.children.find(x => x.path == obj.path)) {
          constant.children.push(obj)
        }
      }
      if (element == AdminRoles.show_faqs) {
        let obj = { path: "/constant/faq", title: "FAQ", type: "link" };
        if (!constant.children.find(x => x.path == obj.path)) {
          constant.children.push(obj)
        }
      }
      if (element == AdminRoles.show_contact_us_messages) {
        let obj = { path: "/constant/contact", title: "ContactUs", type: "link" };
        if (!constant.children.find(x => x.path == obj.path)) {
          constant.children.push(obj)
        }
      }

      if (element == AdminRoles.show_orders) {
        if (!this.MENUITEMS.find(x => x.path == Orders.path)) {
          this.MENUITEMS.push(Orders);
        }
      }

      //finance_statistics
      if (element == AdminRoles.show_finance_report) {
        if (!this.MENUITEMS.find(x => x.path == finance_statistics.path)) {
          this.MENUITEMS.push(finance_statistics);
        }
      }
      if (element == AdminRoles.show_branches) {
        if (!this.MENUITEMS.find(x => x.path == Branches.path)) {
          this.MENUITEMS.push(Branches);
        }
      }

      if (element == AdminRoles.show_coupons) {
        if (!this.MENUITEMS.find(x => x.path == Coupons.path)) {
          this.MENUITEMS.push(Coupons);
        }
      }


      if (element == AdminRoles.show_admins) {
        let obj = { path: "/users/admin/list", title: "Admins", type: "link" };
        if (!Admins.children.find(x => x.path == obj.path)) {
          Admins.children.push(obj)
        }
      }

      if (element == AdminRoles.show_roles) {
        let obj = { path: "/constant/roles", title: "Roles", type: "link" };
        if (!Admins.children.find(x => x.path == obj.path)) {
          Admins.children.push(obj)
        }
      }

      if (element == AdminRoles.show_clients) {
        if (!this.MENUITEMS.find(x => x.path == Clients.path)) {
          this.MENUITEMS.push(Clients);
        }
      }

      if (element == AdminRoles.show_vendors) {
        let obj = { path: "/users/brands", title: "Shops", type: "link" };
        if (!Shops.children.find(x => x.path == obj.path)) {
          Shops.children.push(obj)
        }
      }

      if (element == AdminRoles.show_open_vendor_requests) {
        let obj = { path: "/users/request", title: "newRequests", type: "link" };
        if (!Shops.children.find(x => x.path == obj.path)) {
          Shops.children.push(obj)
        }
      }

      if (element == AdminRoles.show_products) {
        let obj = { path: "/products/list", title: "Products", type: "link" };
        if (!Products.children.find(x => x.path == obj.path)) {
          Products.children.push(obj)
        }
      }

      if (element == AdminRoles.show_products) {
        let obj = { path: "/products/new", title: "newProdcuts", type: "link" };
        if (!Products.children.find(x => x.path == obj.path)) {
          Products.children.push(obj)
        }
      }

      if (element == AdminRoles.show_products_in_stock) {
        let obj = { path: "/products/limited", title: "LimitedProdcut", type: "link" };
        if (!Products.children.find(x => x.path == obj.path)) {
          Products.children.push(obj)
        }
      }



      if (element == AdminRoles.show_withdraw_request) {
        if (!this.MENUITEMS.find(x => x.path == Withdraw.path)) {
          this.MENUITEMS.push(Withdraw);
        }
      }

      if (element == AdminRoles.show_vendor_reviews) {
        let obj = { path: "/review/brand", title: "BrandReview", type: "link" };
        if (!Review.children.find(x => x.path == obj.path)) {
          Review.children.push(obj)
        }
      }

      if (element == AdminRoles.show_product_reviews) {
        let obj = { path: "/review/product", title: "ProductReview", type: "link" };
        if (!Review.children.find(x => x.path == obj.path)) {
          Review.children.push(obj)
        }
      }

      if (element == AdminRoles.show_sales_report) {
        let obj = { path: "/report/sales", title: "SalesReport", type: "link" };
        if (!Reports.children.find(x => x.path == obj.path)) {
          Reports.children.push(obj)
        }
      }

      if (element == AdminRoles.show_earning_report) {
        let obj = { path: "/report/earning", title: "EarningReport", type: "link" };
        if (!Reports.children.find(x => x.path == obj.path)) {
          Reports.children.push(obj)
        }
      }

      if (element == AdminRoles.show_finance_report) {
        let obj = { path: "/report/finance", title: "FinanceReport", type: "link" };
        if (!Reports.children.find(x => x.path == obj.path)) {
          Reports.children.push(obj)
        }
      }

      if (element == AdminRoles.show_notifications) {
        if (!this.MENUITEMS.find(x => x.path == Notification.path)) {
          this.MENUITEMS.push(Notification);
        }
      }

      // if(element == AdminRoles.show_titles){
      //   let obj =  { path: "/guide/titles", title: "SectionTitle", type: "link" };
      //   if(!GuideLandingPage.children.find(x=>x.path == obj.path)){
      //     GuideLandingPage.children.push(obj)
      //   }
      // }

      if (element == AdminRoles.show_banner_page) {
        let obj = { path: "/guide/slider", title: "Sliders", type: "link" };
        if (!GuideLandingPage.children.find(x => x.path == obj.path)) {
          GuideLandingPage.children.push(obj)
        }
      }

      if (element == AdminRoles.show_simplicity_and_transparency) {
        let obj = { path: "/guide/simplicity_and_transparency",
         title: "safeSpace", type: "link" };
        if (!GuideLandingPage.children.find(x => x.path == obj.path)) {
          GuideLandingPage.children.push(obj)
        }
      }

      // if (element == AdminRoles.show_story) {
      //   let obj = { path: "/guide/story", title: "VendorStory", type: "link" };
      //   if (!GuideLandingPage.children.find(x => x.path == obj.path)) {
      //     GuideLandingPage.children.push(obj)
      //   }
      // }

      // if(element == AdminRoles.show_feature_category){
      // }

      if (element == AdminRoles.show_feature_join_us) {
        // let _obj = { path: "/guide/feature", title: "Feature", type: "link" };
        // if (!GuideLandingPage.children.find(x => x.path == _obj.path)) {
        //   GuideLandingPage.children.push(_obj)
        // }
        
        let obj = { path: "/guide/join", title: "advantages", type: "link" };
        // let obj = { path: "/guide/join", title: "FeatureJoinUs", type: "link" };
        if (!GuideLandingPage.children.find(x => x.path == obj.path)) {
          GuideLandingPage.children.push(obj)
        }
      }



      // if(element == AdminRoles.show_advantages_of_simplicity){
      //   let obj =  { path: "/guide/advantages_of_simplicity", title: "AdvantagesOfSimplicity", type: "link" };
      //   if(!GuideLandingPage.children.find(x=>x.path == obj.path)){
      //     GuideLandingPage.children.push(obj)
      //   }
      // }

      if (element == AdminRoles.show_what_you_will_find) {
        let obj = { path: "/guide/what_will_you_find", title: "WhatYouWillFind", type: "link" };
        if (!GuideLandingPage.children.find(x => x.path == obj.path)) {
          GuideLandingPage.children.push(obj)
        }
      }

      if (element == AdminRoles.show_simple_tools) {
        let obj = { path: "/guide/tool", title: "becomeVendor", type: "link" };
        if (!GuideLandingPage.children.find(x => x.path == obj.path)) {
          GuideLandingPage.children.push(obj)
        }
      }

      // if (element == AdminRoles.show_help_around_clock) {
      //   let obj = { path: "/guide/help", title: "HelpAroundClock", type: "link" };
      //   if (!GuideLandingPage.children.find(x => x.path == obj.path)) {
      //     GuideLandingPage.children.push(obj)
      //   }
      // }

    });

    //parents


    if (Shops.children.length > 0) {
      this.MENUITEMS.push(Shops);
    }
    if (Products.children.length > 0) {
      this.MENUITEMS.push(Products);
    }
    if (constant.children.length > 0) {
      this.MENUITEMS.push(constant);
    }

    if (Admins.children.length > 0) {
      this.MENUITEMS.push(Admins);
    }


    // if (Requests.children.length > 0) {
    //   this.MENUITEMS.push(Requests);
    // }

    if (Review.children.length > 0) {
      this.MENUITEMS.push(Review);
    }
    if (Reports.children.length > 0) {
      this.MENUITEMS.push(Reports);
    }


    if (GuideLandingPage.children.length > 0) {
      this.MENUITEMS.push(GuideLandingPage);
    }

    this.MENUITEMS.sort((a, b) => (a.orderNum > b.orderNum) ? 1 : -1);

    return this.MENUITEMS;
  }
  //Array
  items = new BehaviorSubject<Menu[]>(this.getMenu());

  // MENUITEMS: Menu[] = [
  //   {
  //     path: "/dashboard/default",
  //     title: "Dashboard",
  //     icon: "home",
  //     type: "link",
  //     active: true,
  //   },
  //   {
  //     title: "Constant",
  //     icon: "slack",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/constant/settings", title: "Settings", type: "link" },
  //       // { path: "/constant/country", title: "Country", type: "link" },
  //       { path: "/constant/category",    title: "Category", type: "link" },
  //       // { path: "/constant/subcategory", title: "SubCategory", type: "link" },
  //       { path: "/constant/bank", title: "Banks", type: "link" },
  //       { path: "/constant/slider",  title: "Sliders", type: "link" },
  //       { path: "/constant/social",  title: "SocialLink", type: "link" },
  //       { path: "/constant/staticpage",  title: "Staticpage", type: "link" },
  //       { path: "/constant/faq",  title: "FAQ", type: "link" },
  //       { path: "/constant/contact",  title: "ContactUs", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Branches",
  //     icon: "package",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/constant/branch", title: "Branches", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Coupons",
  //     icon: "folder-minus",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/coupon/list", title: "Coupons", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Admins",
  //     icon: "user",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/constant/roles", title: "Roles", type: "link" },
  //       { path: "/users/admin/list", title: "Admins", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Clients&Brands",
  //     icon: "users",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/users/clients", title: "Clients", type: "link"},
  //       { path: "/users/brands", title: "Brands", type: "link"},
  //     ],
  //   }, 
  //   {
  //     title: "Requests",
  //     icon: "plus",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/users/request", title: "Brands", type: "link"},
  //     ],
  //   }, 
  //   {
  //     title: "Products",
  //     icon: "list",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/products/list", title: "Products", type: "link"},
  //     ],
  //   },
  //   {
  //     title: "Orders",
  //     icon: "dollar-sign",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/orders/list", title: "Orders", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Withdraw",
  //     icon: "layers",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/withdraw/list", title: "Withdraw", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Review",
  //     icon: "star",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/review/brand", title: "BrandReview", type: "link" },
  //       { path: "/review/product", title: "ProductReview", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Reports",
  //     icon: "file-text",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/report/sales", title: "SalesReport", type: "link" },
  //       { path: "/report/earning", title: "EarningReport", type: "link" },
  //       { path: "/report/finance", title: "FinanceReport", type: "link" },
  //     ],
  //   },
  //   {
  //     title: "Notification",
  //     icon: "message-square",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "/notification/all", title: "Notification", type: "link" },
  //     ],
  //   },
  //   // {
  //   //   title: "Messages",
  //   //   icon: "message-circle",
  //   //   type: "sub",
  //   //   active: false,
  //   //   children: [
  //   //     { path: "/message/all", title: "Messages", type: "link" },
  //   //   ],
  //   // },
  // ];
  //items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
