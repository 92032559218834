// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  key: "7j@KEY",
  urls: {
    upload: "upload-image",
    multi_upload: "upload-images",
    auth: {
      signin: "signin",
      signout: "signout",
      refresh: "refresh-token",
      signup: "signup",
      forgot: "forget-password",
      reset: "chnage-password",
    },
    admin: {
      list: "admins/lists",
      single: "admins/edit/",
      add: "admins/create",
      update: "admins/edit/",
      profile: "profile/edit",
      change_password: "profile/update-password",
      status: "admins/change-status/"
    },
    roles: {
      list: "roles/lists",
      permession: "roles/all-permissions",
      add: "roles/create",
      single: "roles/edit/",
      update: "roles/edit/",
      delete: "roles/delete/"
    },
    branch: {
      all: "branches/lists?is_paginate=0",
      list: "branches/lists?is_paginate=1",
      single: "branches/edit/",
      create: "branches/create",
      update: "branches/edit/",
      delete: "branches/delete/",
      status: "branches/change-status/"
    },
    coupon: {
      list: "coupons/lists",
      single: "coupons/edit/",
      add: "coupons/create",
      update: "coupons/edit/",
      delete: "coupons/delete/",
      status: "coupons/change-status/"
    },
    advs: {
      all: "banners/lists?title=",
      single: "banners/edit/",
      update: "banners/edit/",
      status: "banners/change-status/",
    },
    country: {
      all: "constants/countries/lists?is_paginate=0",
      list: "constants/countries/lists?is_paginate=1",
      single: "constants/countries/edit/",
      insert: "constants/countries/create",
      update: "constants/countries/edit/",
      delete: "constants/countries/delete/",
    },
    governorate: {
      all: "constants/governorates/lists?is_paginate=0",
      update: "/governorate/update/",
      add: "/governorate/add",
      delete: "/governorate/delete/",
    },
    city: {
      all: "constants/cities/lists?is_paginate=0",
      update: "/city/update/",
      add: "/city/add",
      delete: "/city/delete/",
    },
    settings: {
      all: "settings/lists",
      update: "settings/update",
    },
    notification: {
      search: "notifications/lists",
      readAll: "notifications/read-all",
      seen: "notifications/seen/",
      add: "notifications/send",
      read: "notifications/read/"
    },
    sms: {
      add: "sms/send",
      log: "sms/logs-lists"
    },
    message: {
      all: "/message/all",
      count: "/message/count",
      add: "/message/add",
      update: "/message/update/",
      delete: "/message/delete",
    },
    social: {
      all: "social-media/lists",
      update: "social-media/update",
    },
    faq: {
      all: "faqs/lists?title=",
      single: "faqs/edit/",
      insert: "faqs/create",
      update: "faqs/edit/",
      delete: "faqs/delete/",
      sort: "faqs/sorting",
    },
    blog: {
      all: "/blog/all",
      single: "/blog/single/",
      insert: "/blog/add",
      update: "/blog/update/",
      delete: "/blog/delete/",
    },
    about: {
      all: "/about/all",
      delete: "/about/delete/",
      update: "/about/update/",
      single: "/about/single/",
      insert: "/about/add"
    },
    contact: {
      all: "contact-us/lists",
      delete: "contact-us/delete/",
      reply: "contact-us/send-reply/"
    },
    static: {
      all: "pages/lists?title=",
      single: "pages/edit/",
      update: "pages/edit/",
      insert: "pages/create",
      delete: "pages/delete/",
    },
    category: {
      all: "constants/product_categories/lists?is_paginate=0",
      list: "constants/product_categories/lists?is_paginate=1",
      single: "constants/product_categories/edit/",
      insert: "constants/product_categories/create",
      update: "constants/product_categories/edit/",
      delete: "constants/product_categories/delete/",
      sort: "constants/product_categories/sorting",
    },
    continent: {
      all: "constants/continents/lists?is_paginate=0",
      list: "constants/continents/lists?is_paginate=1",
      single: "constants/continents/edit/",
      insert: "constants/continents/create",
      update: "constants/continents/edit/",
      delete: "constants/continents/delete/",
      changeStatus: "constants/continents/change-status/",
    },
    continentSilders: {
      list: "continent-sliders/lists",
      single: "continent-sliders/edit/",
      insert: "continent-sliders/create",
      update: "continent-sliders/edit/",
      delete: "continent-sliders/delete/",
    },
    subcategory: {
      all: "constants/product_subcategories/lists?is_paginate=0",
      list: "constants/product_subcategories/lists?is_paginate=1",
      single: "constants/product_subcategories/edit/",
      insert: "constants/product_subcategories/create",
      update: "constants/product_subcategories/edit/",
      delete: "constants/product_subcategories/delete/",
    },
    bank: {
      all: "constants/banks/lists?is_paginate=0",
      list: "constants/banks/lists?is_paginate=1",
      single: "constants/banks/edit/",
      insert: "constants/banks/create",
      update: "constants/banks/edit/",
      delete: "constants/banks/delete/",
    },
    main_feature: {
      all: "/feature/main",
      delete: "/main-feature/delete/",
    },
    product: {
      limited: "products/products-in-stock",
      search: "products/lists",
      single: "products/edit/",
      insert: "products/create",
      update: "products/edit/",
      delete: "products/delete/",
      export_excel: "products/export-excel",
      delete_property_image: "products/delete-property-image/",
      delete_image: "product/delete-image/",
      attribute: "product/feature",
      disabled: "products/change-status/",
      review: "reviews/product/lists",
      count: "reviews/product/statistics",
      available_qty: "stores/",
      log: "stores/",
      redo: "stores/"
    },
    product_tag: {
      search: "/tag/find",
      all: "/product/tag/",
      insert: "/product/tags",
    },
    product_main_feature: {
      single: "/product/feature/",
      insert: "/product-main-feature/add",
      update: "/product-main-feature/update/",
      delete: "/product-main-feature/delete/",
    },
    product_sub_feature: {
      single: "/prodcut-sub-feature/single/",
      insert: "/product-sub-feature/add",
      update: "/product-sub-feature/update/",
      delete: "/product-sub-feature/delete/",
    },
    vendor: {
      list: "vendors/lists",
      search: "vendors/lists",
      single: "vendors/edit/",
      create: "vendors/create",
      status: "vendors/change-status/",
      update: "vendors/edit/",
      delete: "vendors/delete/",
      export_excel: "vendors/export-excel",
      follower: "vendors/follower/",
      review: "reviews/vendor/lists",
      count: "reviews/vendor/statistics"
    },
    review: {
      channge: "reviews/change-status/",
      delete: "reviews/delete/"
    },
    user: {
      search: "users/search",
      list: "users/lists",
      single: "users/edit/",
      block: "users/block/",
      delete: "users/delete/",
      addd: "users/create",
      update: "users/edit/",
      export_excel: "users/export-excel",
      address: "user/address",
      all_address: "user/all-address/",
    },
    order: {
      list: "orders/lists",
      single: "orders/view/",
      update: "orders/change-status/",
      vendor: "orders/vendor-orders-lists/",
      export_excel: "orders/export-excel",
    },
    order_details: {
      all: "/order_details/all",
      single: "/order-details/single/"
    },
    dashboard: {
      counters: "statisicals",
      vendors_list: "statisicals/general-reports/vendors",
      branches_list: "statisicals/general-reports/branches"
    },
    request: {
      list: "vendors-verifications-requests/lists",
      update: "vendors-verifications-requests/change-status/",
      delete: "vendors-verifications-requests/delete/"
    },
    report: {
      saleing_list: "statisicals/reports/best_seller",
      saleing_excel: "statisicals/reports/best_seller/export-excel",
      earing_list: "statisicals/reports/highest_earnings",
      earning_excel: "statisicals/reports/highest_earnings/export-excel",
      financial_list: "statisicals/reports/finance_statement",
      financial_excel: "statisicals/reports/finance_statement/export-excel",

    },
    charts: {
      orders: "statisicals/charts/num-orders-according-branches",
      category: "statisicals/charts/sales-by-category",
    },
    withdraw: {
      list: "withdrawal-requests/lists",
      details: "withdrawal-requests/view/",
      update: "withdrawal-requests/change-status/",
      delete: "withdrawal-requests/delete/",
    },
    guide: {
      all: "banners/edit/by-key/sellers_beginners_guide",
      story: "vendors-stories/lists?title=",
      delete_story: "vendors-stories/delete/",
      add_story: "vendors-stories/create",
      edit_story: "vendors-stories/edit/",
      single_story: "vendors-stories/edit/",
      titles: "section-texts/lists",
      title_update: "section-texts/edit/",
      single_title: "section-texts/edit/"
    },
    feature: {
      all: "constants/feature_categories/lists?is_paginate=0",
      list: "constants/feature_categories/lists?is_paginate=1",
      single: "constants/feature_categories/edit/",
      insert: "constants/feature_categories/create",
      update: "constants/feature_categories/edit/",
      delete: "constants/feature_categories/delete/",
    },
    join_us: {
      all: "feature-joining-us/lists?title=",
      list: "feature-joining-us/lists?title=",
      single: "feature-joining-us/edit/",
      insert: "feature-joining-us/create",
      update: "feature-joining-us/edit/",
      delete: "feature-joining-us/delete/",
    },
    simple_tools: {
      list: "our-benefits/simple_tools/lists?title=",
      single: "our-benefits/simple_tools/edit/",
      insert: "our-benefits/simple_tools/create",
      update: "our-benefits/simple_tools/edit/",
      delete: "our-benefits/simple_tools/delete/",
    },
    what_you_will_find: {
      list: "our-benefits/what_you_will_find/lists?title=",
      single: "our-benefits/what_you_will_find/edit/",
      insert: "our-benefits/what_you_will_find/create",
      update: "our-benefits/what_you_will_find/edit/",
      delete: "our-benefits/what_you_will_find/delete/",
    },
    help_around_clock: {
      list: "our-benefits/help_around_clock/lists?title=",
      single: "our-benefits/help_around_clock/edit/",
      insert: "our-benefits/help_around_clock/create",
      update: "our-benefits/help_around_clock/edit/",
      delete: "our-benefits/help_around_clock/delete/",
    },
    advantages_of_simplicity: {
      list: "our-benefits/advantages_of_simplicity/lists?title=",
      single: "our-benefits/advantages_of_simplicity/edit/",
      insert: "our-benefits/advantages_of_simplicity/create",
      update: "our-benefits/advantages_of_simplicity/edit/",
      delete: "our-benefits/advantages_of_simplicity/delete/",
    },
    simplicity_and_transparency: {
      list: "our-benefits/simplicity_and_transparency/lists?title=",
      single: "our-benefits/simplicity_and_transparency/edit/",
      insert: "our-benefits/simplicity_and_transparency/create",
      update: "our-benefits/simplicity_and_transparency/edit/",
      delete: "our-benefits/simplicity_and_transparency/delete/",
    },
  },
  currency: '',
  // base_url:"https://7jewels-devolper.nstechs-dev.com/api/panel/",
  // base_url: "https://7jewels-stage.nstechs-dev.com/api/panel/",
  // base_url: "http://jewels_backend.test/api/panel/",
  // base_url: "http://jewels-staging.test/api/panel/",
  // base_url: "https://jewels.nstechs-dev.com/api/panel/",
  base_url: "https://jewels.nstechs-team.net/api/panel/",
  googleAPI: 'AIzaSyDa0BdEB-otNYNMCa5wHCks__iwqsMA08g'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
