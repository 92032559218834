import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { AdminRoles, appConstant } from "src/app/service/appConstant";
import { environment } from "src/environments/environment";
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from "src/app/service/ConstantServiceWrapper.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

type UserFields = "password" | "password_confirmation";
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {
  @ViewChild("Login") Login: ElementRef;
  email = ""
  token = ""
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    password: "",
    password_confirmation:""
  };
  public errorMessage: any;
  showLoader = false;

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    public toster: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    public route: ActivatedRoute,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
      this.loginForm = fb.group({
        password: ["", Validators.required],
        password_confirmation: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.email = params["email"] == undefined ? null : params["email"];
      this.token = params["token"] == undefined ? null : params["token"];
    })
  }

  // Simple Login
  login(form) {
    this.changePasswordAdmin(form);
  }


/**
 * This function changes the password for an admin user and navigates to the login page upon success.
 * @param conent - There seems to be a typo in the parameter name, it should be "content" instead of
 * "conent". However, based on the code, it seems that the parameter is not being used in the function.
 * @returns The function is not returning anything explicitly, but it may return undefined if the
 * condition inside the if statement is true and the function is exited early.
 */
  changePasswordAdmin(conent) {
    this.showLoader = true;
    let body = {
      password: this.loginForm.controls['password'].value,
      password_confirmation: this.loginForm.controls['password_confirmation'].value,
      email: this.email,
      token: this.token
    }

    if(this.token == '' || this.email == ''){
      this.toastr.error(this.translate.instant("GENERAL.Unknown"))
      return;
    }
    this.add(`${environment.urls.auth.reset}`, body, false).subscribe(res_data=>{
      if(res_data.status){
        this.router.navigate(['/auth/login']);
      }
    })
  }
}
