import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CustomizerService } from './shared/services/customizer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'endless';


  constructor(
    private translate: TranslateService,
    public customize: CustomizerService,
    public toaster : ToastrService,
    private modalService: NgbModal,
  ) {

  }

  
  ngOnInit(): void {
    if (localStorage.getItem("lang")) {
      let lang = localStorage.getItem("lang");
      let dir = lang == "ar" ? "rtl" : "ltr";
      this.translate.use(lang);
      this.customize.setLayoutType(dir);
      this.customize.setLayout(dir);
    } else {
      localStorage.setItem("lang", "en");
      this.translate.use("en");
      this.customize.setLayoutType("ltr");
      this.customize.setLayout("ltr");
    }
  }

}

