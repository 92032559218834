import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { OrderDetails } from '../../../../app/model/model';
import { Constant } from '../../../../app/service/appConstant';
import { ConstantServiceWrapper } from '../../../../app/service/ConstantServiceWrapper.service';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent extends BaseComponent  implements OnInit {
  @Input() item:OrderDetails = new OrderDetails()
  @Output() public action= new EventEmitter();
  ORDER_STATUS_ARRAY = Constant.ORDER_STATUS_ARRAY;
  ORDER_STATUS;
  lang = ""
  constructor(
    public helper: ConstantServiceWrapper,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
  ) {
    super(helper, toastr, translate, activeModal, modalService )
    this.ORDER_STATUS = Constant.ORDER_STATUS;
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
  
  }

 /**
  * This function takes an order status as input and returns the corresponding translated status
  * message.
  * @param {string} orderStatus - A string representing the status of an order. It could be one of the
  * following values: "Pending", "Progress", "Finished", or "UserCanceled".
  * @returns a string value which represents the translated order status based on the input orderStatus
  * parameter.
  */
  getStatus(orderStatus:string):string{
    var status = ""
    switch (orderStatus) {
      case Constant.ORDER_STATUS.Pending:
        status = this.translate.instant('ORDER_STATUS.Pending')
        break;
      case Constant.ORDER_STATUS.Progress:
        status = this.translate.instant('ORDER_STATUS.Progress')
        break;
      case Constant.ORDER_STATUS.Finished:
        status = this.translate.instant('ORDER_STATUS.Finished')
        break;
      case Constant.ORDER_STATUS.UserCanceled:
        status = this.translate.instant('ORDER_STATUS.UserCanceled')
        break;     
      default:
        break;
    }
    return status
  }

/**
 * This function returns the translated status of an order based on its current status.
 * @param {string} orderStatus - A string representing the status of an order. It could be one of the
 * following values: "Pending", "Progress", "Finished", or "UserCanceled".
 * @returns a string value which represents the translated status of an order based on its current
 * status. The translated status is determined by the input parameter "orderStatus" which is a string
 * value representing the current status of the order.
 */
  getButtonStatus(orderStatus:string):string{
    var status = ""
    switch (orderStatus) {
      case Constant.ORDER_STATUS.Pending:
        status = this.translate.instant('ORDER_STATUS.Pending')
        break;
      case Constant.ORDER_STATUS.Progress:
        status = this.translate.instant('ORDER_STATUS.Deliverd')
        break;
      case Constant.ORDER_STATUS.Finished:
        status = this.translate.instant('ORDER_STATUS.Finished')
        break;
      case Constant.ORDER_STATUS.UserCanceled:
        status = this.translate.instant('ORDER_STATUS.UserCanceled')
      break;             
      default:
        break;
    }
    return status
  }

/**
 * This function updates the status of an order with a given ID.
 * @param {string} orderStatus - A string representing the updated status of an order.
 * @param {number} orderId - The orderId parameter is a number that represents the unique identifier of
 * an order. It is used to identify the specific order that needs to be updated.
 */
  updateOrder(orderStatus:string, orderId:number):void{
    // let status = this.getUpdatedStatus(orderStatus);
    let body = { status: orderStatus }
    this.edit(`${environment.urls.order.update}${orderId}`, body, false).subscribe(res=>{
      if(res.status){
        this.action.emit(res.status);
        this.activeModal.close()
      }
    })
  }


  onSelect(val, orderId){
    this.updateOrder(val, orderId)
  }

}
