import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { AdminRoles, appConstant } from "src/app/service/appConstant";
import { environment } from "src/environments/environment";
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConstantServiceWrapper } from "src/app/service/ConstantServiceWrapper.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

type UserFields = "email" | "password";
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends BaseComponent implements OnInit {
  @ViewChild("Login") Login: ElementRef;

  public newUser = false;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    email: "",
    password: "",
  };
  public errorMessage: any;
  showLoader = false;

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    public toster: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super(helper,toastr,translate,activeModal,modalService)
      this.loginForm = fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  // Simple Login
  login(form) {
    this.loginAdmin(form);
  }


/**
 * This function logs in an admin user, encrypts their token and roles, and sets them in local storage.
 * @param conent - It is a variable that contains the content to be sent in the login request. It is
 * expected to be in JSON format.
 */
  loginAdmin(conent) {
    this.showLoader = true;
    this.add(`${environment.urls.auth.signin}`,  JSON.stringify(conent), false).subscribe(res_data=>{
      if(res_data.status){
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        let items = res_data.items as any
        let cryptoToken = CryptoJS.AES.encrypt(items.token, environment.key).toString();
        localStorage.setItem("token", cryptoToken);
        let keys =  Object.keys(items.user.role.permissions) as Array<string>
        var arr = []
        debugger;
        keys.forEach(key => {
          let value = items.user.role.permissions[key];
          if(value && value.items && value.items.length > 0){
            value.items.forEach( el => {
              if(el.is_check){
                AdminRoles.All_Pages.forEach(element => {
                  if(element.permession == el.key){
                    arr.push(element)
                  }
                });
              }
            });
          }
        });
        let roles = CryptoJS.AES.encrypt(JSON.stringify(arr), environment.key).toString();
        localStorage.setItem("roles", roles);

        setTimeout(() => {
          this.showLoader = false;
          this.Login.nativeElement.click();
        }, 1000); 
      }
    })
  }
}
