import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";
import { ConstantServiceWrapper } from "../../../service/ConstantServiceWrapper.service";
import { AdminRoles, appConstant, Constant, UserType } from "src/app/service/appConstant";
import { BaseComponent } from "src/app/components/base/base.component";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { PagingResponse } from "src/app/model/generalModel";
import * as CryptoJS from "crypto-js";

var body = document.getElementsByTagName("body")[0];

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

export class HeaderComponent extends BaseComponent implements OnInit {
  notificationCount = 0;
  notifications = [];
  admin_id = "";
  NotificationTypes;
  isHasNotificationPermission = false;
  isSupportAr = Constant.isSupportAr

  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false;
  public right_sidebar: boolean = false;
  public text: string;
  public elem;
  public isOpenMobile: boolean = false;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  user_id;
  type;
  userImage;
  lang;
  isLoading = false
  isShowNotification = true;
  isShowLanguage = false;
  isShowProfile = false;
  roles = []
  public get UserType(): typeof UserType {
    return UserType;
  }

  constructor(
    public helper: ConstantServiceWrapper,
    public toastr: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,

    @Inject(DOCUMENT) private document: any,
    public navServices: NavService,

  ) {
    super(helper, toastr, translate, activeModal, modalService)
    this.limit = 5
    this.NotificationTypes = Constant.NotificationTypes
    translate.setDefaultLang("en");
    this.user_id = localStorage.getItem("admin_id");
    this.type = localStorage.getItem("type");
    this.userImage = localStorage.getItem("image");
    if (localStorage.getItem("lang")) {
      let currentLang = localStorage.getItem("lang");
      this.lang = currentLang == "ar" ? "AR" : "EN";
    }
    this.getDate();
    this.roles = this.getMyRoles()
    let show_notifications = this.roles.find(x => x.permession == AdminRoles.show_notifications)
    if (show_notifications) {
      this.isHasNotificationPermission = true
    }

    // this.getUnReadCount()
  }

  getDate() {
    this.limit = 5
    this.get(`${environment.urls.notification.search}?is_paginate=0&page=${this.page}&limit=${this.limit}&title=&from_date=&to_date=&not_read=1`).subscribe((res) => {
      if (res.status) {
        let items = res.items as any
        let response = items as PagingResponse<any>
        this.notifications = response.items
        this.notificationCount = response['statistics']['not_read']
      }
    })
  }

  ngOnDestroy() {
    this.removeFix();
  }

  private getData() {
    this.helper.change.subscribe((value) => {
      this.isLoading = value;
    });
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    // this.translate.use(lang);

    // this.translate.use(lang);
    // this.customize.setLayoutType(val);
    // this.layoutType = val;
    // this.lang = lang === "ar" ? "AR" : "EN";
    localStorage.setItem("lang", lang);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return (this.menuItems = []);
    let items = [];
    term = term.toLowerCase();
    this.items.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(term) &&
        menuItems.type === "link"
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(term) &&
          subItems.type === "link"
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((suSubItems) => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
      this.checkSearchResultEmpty(items);
      this.menuItems = items;
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length) this.searchResultEmpty = true;
    else this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe((menuItems) => {
      this.items = menuItems;
    });

    this.getData();
  }

  MarkAllAsRead() {
    this.add(`${environment.urls.notification.readAll}`, {}, false, false).subscribe((res) => {
      if (res.status) {
        this.getDate()
      }
    })
  }
  seenNotification(id) {
    this.add(`${environment.urls.notification.seen}${id}`, {}, false).subscribe((res) => {
      if (res.status)
        this.getData
    });
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  SignOut() {
    this.showLoader = true;
    this.add(`${environment.urls.auth.signout}`, {}, false, false).subscribe(res_data => {
      localStorage.removeItem("token");
      localStorage.removeItem("lang");
      localStorage.removeItem("roles");

      setTimeout(() => {
        this.showLoader = false;
        this.router.navigate(["/auth/login"]);
      }, 1000);
    })
  }

  ShowNotificationMenu() {
    this.isShowNotification = !this.isShowNotification;
  }


  onClick(event) {
    this.isShowNotification = false
  }

  ShowLanguageMenu() {
    this.isShowLanguage = !this.isShowLanguage;
  }

  ShowProfile() {
    this.isShowProfile = !this.isShowProfile;
  }

  openNotification(item) {
    this.add(`${environment.urls.notification.read}${item.id}`, {}, false, false).subscribe((res) => {
      this.page = 1
      this.getDate()
      if (item.action_type == 'vendors_verifications_requests' || item.action_type == 'vendor_requests') {
        this.router.navigate(['/users/brands/details/' + item.action_id])
      }
      if (item.action_type == 'orders') {
        this.router.navigate(['/orders/orderDetails/' + item.action_id])
      }
      if (item.action_type == "products") {
        this.router.navigate(['/products/view/' + item.action_id])
      }
      if (item.action_type == "withdrawal_requests") {
        this.router.navigate(['/withdraw/details/' + item.action_id])
      }
      if (item.action_type == "minimum_quantity_products") {
        this.router.navigate(['/products/details/' + item.action_id])
      }
      if (item.action_type == 'general') {
        // this.router.navigate(['/orders/orderDetails/'+item.id]) 
      }
    });
  }

  getMyRoles() {
    if (localStorage.getItem("roles")) {
      let decryptToken = CryptoJS.AES.decrypt(localStorage.getItem("roles"), environment.key);
      var decryptedData = decryptToken.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } else {
      return ""
    }
  }
}
